import { Box, styled, Typography } from '@mui/material'
import React from 'react'


const ManiLastBox = styled(Box)(({ theme }) => ({
  backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/23.jpg")',
  backgroundRepeat: 'no-repeat, repeat',
  height: '550px',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  color: '#fff',
  backgroundAttachment: 'fixed',
  [theme.breakpoints.down('lg')]: {
    marginTop: '50px',
    height: '290px',

  }
}))


const MainBox = styled(Box)(({ theme }) => ({
  padding: '220px',
  [theme.breakpoints.down('lg')]: {
    padding: '55px',
  }
}))




const QuichText = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontSize: '62px',
  color: '#fffff',
  lineHeight: '70px',
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    fontSize: '28px',
    lineHeight: '80px',
  }
}))



const LaundryText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: '#fffff',
  fontSize: '18px',
  fontWeight: '500',
  marginTop: '-210px',
  [theme.breakpoints.down('lg')]: {
    marginTop: '-60px',
  }
}))



const SecondMain = styled(Box)(({ theme }) => ({
  width: '200px',
  height: '50px',
  background: '#305349',
  border: '1px solid green',
  color: 'white',
  marginLeft: '663px',
  marginTop: '15px',
  borderRadius: '7px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '95px',
    marginTop: '15px',
  }
}))



const SeduleText = styled(Typography)`
    margin-left: 22.5px;
    margin-top: 10px;
`;

const ThirdBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: '70px',
  marginBottom: '35px',
  [theme.breakpoints.down('lg')]: {
    display: 'block',
    marginTop: '0px',
    marginBottom: '0px',
  }
}))


const Flexible = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: '500',
  color: '#fffff',
  marginLeft: '215px',
  marginTop: '25px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '18px',
    textAlign: 'center',
    marginLeft: '0px',
    marginTop: '30px',
  }
}))



const SeduleMain = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: '600',
  color: '#305349',
  marginLeft: '215px',
  marginTop: '20px',
  lineHeight: '25px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '22px',
    textAlign: 'center',
    marginLeft: '-5px',
    marginTop: '20px',
    lineHeight: '15px',

  }
}))

const SecondBox = styled(Box)(({ theme }) => ({
  marginTop: '25px',
  marginLeft: '215px',
  [theme.breakpoints.down('lg')]: {
    marginTop: '20px',
    marginLeft: '78px',
    textAlign: 'center',
    width: '60%',
  }

}))

const WhileText = styled(Typography)(({ theme }) => ({
  color: ' #fffff',
  lineHeight: '20px',
  [theme.breakpoints.down('lg')]: {

  }
}))

const FourBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: '215px',
  marginTop: '25px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '32px',
    marginTop: '25px',
  }
}))



const ImageCorser = styled('img')({
  width: '30px',
  height: '30px',


})

const BookText = styled(Typography)`
  color: #305349;
  font-weight: 500;
  font-size: 18px;
  padding: 2.5px;
  margin-left: 11px;
  letter-spacing: 1px;
`;

const ImageBox = styled(Box)(({ theme }) => ({
  marginLeft: '50px',
  marginTop: '-30px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '47px',
    marginTop: '30px',
  }
}))





const ImageFlex = styled('img')(({ theme }) => ({
  width: '550px',
  height: '400px',
  [theme.breakpoints.down('lg')]: {
    width: '300px',
    height: '300px',

  }
}))

const ImageFlexSec = styled('img')(({ theme }) => ({
  width: '550px',
  height: '400px',
  [theme.breakpoints.down('lg')]: {
    width: '300px',
    height: '250px',

  }
}))



const ThirdBoxSec = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: '85px',
  marginBottom: '35px',
  [theme.breakpoints.down('lg')]: {
    display: 'block',
    marginTop: '0px',
    marginBottom: '0px',
  }
}))



const ImageBoxSec = styled(Box)(({ theme }) => ({
  marginLeft: '217px',
  marginTop: '-30px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '47px',
    marginTop: '30px',
  }
}))



const FlexibleSec = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: '500',
  color: 'grey',
  marginLeft: '95px',
  marginTop: '25px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '18px',
    textAlign: 'center',
    marginLeft: '0px',
    marginTop: '30px',
  }
}))




const SeduleMainSec = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: '600',
  color: '#305349',
  marginLeft: '95px',
  marginTop: '20px',
  lineHeight: '25px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '22px',
    textAlign: 'center',
    marginLeft: '5px',
    marginTop: '25px',
    lineHeight: '15px',

  }
}))

const SecondBoxSec = styled(Box)(({ theme }) => ({
  marginTop: '25px',
  marginLeft: '95px',
  [theme.breakpoints.down('lg')]: {
    marginTop: '20px',
    marginLeft: '75px',
    textAlign: 'center',
    width: '60%',
  }

}))



const WhileTextSec = styled(Typography)(({ theme }) => ({
  color: 'grey',
  lineHeight: '20px',
  [theme.breakpoints.down('lg')]: {

  }
}))


const FourBoxSec = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: '95px',
  marginTop: '25px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '32px',
    marginTop: '25px',
  }
}))



const ImageCorserSec = styled('img')({
  width: '30px',
  height: '30px',


})

const BookTextSec = styled(Typography)`
  color: #305349;
  font-weight: 500;
  font-size: 18px;
  padding: 2.5px;
  margin-left: 11px;
  letter-spacing: 1px;
`;


const ThirdBoxThird = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: '85px',
  marginBottom: '35px',
  [theme.breakpoints.down('lg')]: {
    display: 'block',
    marginTop: '0px',
    marginBottom: '0px',
  }
}))



const FlexibleThird = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: '500',
  color: 'grey',
  marginLeft: '215px',
  marginTop: '25px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '18px',
    textAlign: 'center',
    marginLeft: '0px',
    marginTop: '30px',
  }
}))


const SeduleMainThird = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: '600',
  color: '#305349',
  marginLeft: '215px',
  marginTop: '10px',
  lineHeight: '25px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '22px',
    textAlign: 'center',
    marginLeft: '5px',
    marginTop: '20px',
    lineHeight: '15px',

  }
}))

const SecondBoxThird = styled(Box)(({ theme }) => ({
  marginTop: '25px',
  marginLeft: '215px',
  [theme.breakpoints.down('lg')]: {
    marginTop: '20px',
    marginLeft: '78px',
    textAlign: 'center',
    width: '60%',
  }

}))

const WhileTextThird = styled(Typography)(({ theme }) => ({
  color: 'grey',
  lineHeight: '20px',
  [theme.breakpoints.down('lg')]: {

  }
}))

const BookTextThird = styled(Typography)`
  color: #305349;
  font-weight: 500;
  font-size: 18px;
  padding: 2.5px;
  margin-left: 11px;
  letter-spacing: 1px;
`;

const FourBoxThird = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: '215px',
  marginTop: '25px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '32px',
    marginTop: '25px',
  }
}))




const ImageBoxThird = styled(Box)(({ theme }) => ({
  marginLeft: '105px',
  marginTop: '-30px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '47px',
    marginTop: '30px',
  }
}))



const ImageBoxThirdFour = styled(Box)(({ theme }) => ({
  marginLeft: '150px',
  marginTop: '-30px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '47px',
    marginTop: '30px',
  }
}))


const SecondBoxMain = styled(Box)(({ theme }) => ({
  width: '200px',
  height: '50px',
  background: '#305349',
  border: '1px solid green',
  color: 'white',
  marginLeft: '163px',
  marginTop: '45px',
  borderRadius: '7px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '93px',
    marginTop: '20px',
    marginBottom: '25px',
  }
}))


const SeduleBoxText = styled(Typography)`
    margin-left: 22.5px;
    margin-top: 10px;
`;


const SecondBoxMainSec = styled(Box)(({ theme }) => ({
  width: '150px',
  height: '50px',
  background: '#305349',
  border: '1px solid green',
  color: 'white',
  marginLeft: '330px',
  marginTop: '25px',
  borderRadius: '7px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '118px',
    marginTop: '20px',
    marginBottom: '25px',
  }
}))



const SeduleBoxTextSec = styled(Typography)`
    margin-left: 40px;
    margin-top: 10px;
`;


const ThirdBoxSecFourFive = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: '85px',
  marginBottom: '80px',
  [theme.breakpoints.down('lg')]: {
    display: 'block',
    marginTop: '0px',
    marginBottom: '0px',
  }

}))


const Services = () => {
  return (
    <>
      <ManiLastBox>
        <MainBox>
          <QuichText>Quick. Clean. Fresh.</QuichText>
        </MainBox>
        <LaundryText>Your laundry delivered in 24h</LaundryText>
        <SecondMain>
          <SeduleText>Schedule your pickup</SeduleText>
        </SecondMain>
      </ManiLastBox>


      <ThirdBox>
        <Box>
          <Flexible>FLEXIBLE</Flexible>
          <SeduleMain>1. Schedule your</SeduleMain>
          <SeduleMain>collection</SeduleMain>
          <SecondBox>
            <WhileText>Plan your day with ease. Choose a collection and delivery time at your</WhileText>
            <WhileText> convenience.</WhileText>
          </SecondBox>
          <FourBox>
            <ImageCorser src="https://prod-cdn.laundryheap.com/assets/landing/subpages/icons/click-320caffd91e0f796dd60b5b82e7799cd39a77873778b1bd55273bab134fd92e0.svg" alt="" />
            <BookText>Book online or with our mobile app</BookText>
          </FourBox>
          <FourBox>
            <ImageCorser src="https://prod-cdn.laundryheap.com/assets/landing/subpages/icons/moon-73bfffee92a87fb157824864fc26d9fdc9b7c410257157fc3095acb959d08928.svg" alt="" />
            <BookText>Weekend and evening slots available</BookText>
          </FourBox>
        </Box>
        <ImageBox>
          <ImageFlexSec src="https://prod-cdn.laundryheap.com/assets/landing/subpages/how_it_works/benefit_1-2fb56db0c39b006100f9672637de90eaed92cbcf1060f4ddbd03b36be09e9830.png" alt="" />
        </ImageBox>
      </ThirdBox>


      <ThirdBoxSec>
        <ImageBoxSec>
          <ImageFlexSec src="https://prod-cdn.laundryheap.com/assets/landing/subpages/how_it_works/benefit_2-0bc044525fc8cd35630eceba8fab04bfc1333f39cc85a412153a3887145bc795.png" alt="" />
        </ImageBoxSec>
        <Box>
          <FlexibleSec>QUICK & EASY</FlexibleSec>
          <SeduleMainSec>2. Pack your laundry</SeduleMainSec>
          <SecondBoxSec>
            <WhileTextSec>Pack your items in a disposable bag. Use one bag per service. Our</WhileTextSec>
            <WhileTextSec>driver will transfer them to reusable Laundryheap bags which you</WhileTextSec>
            <WhileTextSec>can keep for your next order.</WhileTextSec>
          </SecondBoxSec>
          <FourBoxSec>
            <ImageCorserSec src="https://prod-cdn.laundryheap.com/assets/landing/subpages/icons/bag-c54c1bda693c4b8a3d16421d358329cfa108b1e44a99b6f104520e12a5327fce.svg" alt="" />
            <BookTextSec>Pack one bag per service</BookTextSec>
          </FourBoxSec>
          <FourBoxSec>
            <ImageCorserSec src="https://prod-cdn.laundryheap.com/assets/landing/subpages/icons/weight-ce3401516627da29d2fa034d7e9581b3d83c11ff7330e7d6b4027950124a1c3e.svg" alt="" />
            <BookTextSec>No need to count or weigh your items</BookTextSec>
          </FourBoxSec>
        </Box>
      </ThirdBoxSec>


      <ThirdBoxThird>
        <Box>
          <FlexibleThird>TRANSPARENT</FlexibleThird>
          <SeduleMainThird>3. Wait for our driver</SeduleMainThird>
          <SecondBoxThird>
            <WhileTextThird>You’ll receive a notification when our driver is nearby. They will</WhileTextThird>
            <WhileTextThird>collect your bags and take them to your local cleaning facility.</WhileTextThird>
          </SecondBoxThird>
          <FourBoxThird>
            <ImageCorser src="https://prod-cdn.laundryheap.com/assets/landing/subpages/icons/dial-4f88657f28612b806d6ed6bf0b4a0f86a2a3f8747c6cb1db634d49bdcf76f30f.svg" alt="" />
            <BookTextThird>Regular order updates</BookTextThird>
          </FourBoxThird>
          <FourBoxThird>
            <ImageCorser src="https://prod-cdn.laundryheap.com/assets/landing/subpages/icons/truck-40aa3009a2612006b889fe6948bfa477726469253569ed4686f0001bea176a53.svg" alt="" />
            <BookTextThird>Live driver tracking</BookTextThird>
          </FourBoxThird>
        </Box>
        <ImageBoxThird>
          <ImageFlexSec src="https://prod-cdn.laundryheap.com/assets/landing/subpages/how_it_works/benefit_3-454b7042130e3714be7cac36e51d9886e1a646bd10014291651e50ed22bbd183.png" alt="" />
        </ImageBoxThird>
      </ThirdBoxThird>


      <ThirdBoxSec>
        <ImageBoxSec>
          <ImageFlexSec src="https://prod-cdn.laundryheap.com/assets/landing/subpages/how_it_works/benefit_4-85735ed06d2cb016e2c380a2df83bedd31d1980d47653051835eb2824ff1e174.png" alt="" />
        </ImageBoxSec>
        <Box>
          <FlexibleSec>CONVENIENT</FlexibleSec>
          <SeduleMainSec>4. Relax while we take</SeduleMainSec>
          <SeduleMainSec> care of your laundry</SeduleMainSec>
          <SecondBoxSec>
            <WhileTextSec>Your local partner facility will clean your items with utmost care. Our</WhileTextSec>
            <WhileTextSec>driver will then deliver them back to you whenever you like. You’re in</WhileTextSec>
            <WhileTextSec>full control of your delivery and can always reschedule if not at</WhileTextSec>
            <WhileTextSec>home.</WhileTextSec>
          </SecondBoxSec>
          <FourBoxSec>
            <ImageCorserSec src="https://prod-cdn.laundryheap.com/assets/landing/subpages/icons/bag-c54c1bda693c4b8a3d16421d358329cfa108b1e44a99b6f104520e12a5327fce.svg" alt="" />
            <BookTextSec>Pack one bag per service</BookTextSec>
          </FourBoxSec>
          <FourBoxSec>
            <ImageCorserSec src="https://prod-cdn.laundryheap.com/assets/landing/subpages/icons/weight-ce3401516627da29d2fa034d7e9581b3d83c11ff7330e7d6b4027950124a1c3e.svg" alt="" />
            <BookTextSec>No need to count or weigh your items</BookTextSec>
          </FourBoxSec>
        </Box>
      </ThirdBoxSec>


      <ThirdBoxThird>
        <Box>
          <SeduleMainThird>A convenient laundry solution</SeduleMainThird>
          <SeduleMainThird>that helps protect the</SeduleMainThird>
          <SeduleMainThird>environment.</SeduleMainThird>
          <FourBoxThird>
            <ImageCorser src="https://prod-cdn.laundryheap.com/assets/landing/icons/leaf-6330a3f451b06b9bc56475d40905893218c36b02f2d058b8865170fd297f7a77.svg" alt="" />
            <BookTextThird>Zero-emission delivery vehicles</BookTextThird>
          </FourBoxThird>
          <FourBoxThird>
            <ImageCorser src="https://prod-cdn.laundryheap.com/assets/landing/icons/water-46c10669de81bef4ddfe24f96459c924165098b35cdcd194763e6dd9dfccb30b.svg" alt="" />
            <BookTextThird>Efficient water use</BookTextThird>
          </FourBoxThird>
          <FourBoxThird>
            <ImageCorser src="https://prod-cdn.laundryheap.com/assets/landing/icons/heart_check_yellow-efcaab64544182c2b17bdad4a9ba325740b997388c053962a9311afe62c34016.svg" alt="" />
            <BookTextThird>Trustworthy local cleaners</BookTextThird>
          </FourBoxThird>
          <SecondBoxMainSec>
            <SeduleBoxTextSec>About us</SeduleBoxTextSec>
          </SecondBoxMainSec>
        </Box>
        <ImageBoxThirdFour>
          <ImageFlex src="https://prod-cdn.laundryheap.com/assets/landing/subpages/how_it_works/why_us-45941fbc1971e11bb5a0ee2d32a10e2312872b9ed2b792b76dbb39dbeff52e49.jpg" alt="" />
        </ImageBoxThirdFour>
      </ThirdBoxThird>


      <ThirdBoxSecFourFive>
        <ImageBoxSec>
          <ImageFlex src="Image/24.jpg" alt="" />
        </ImageBoxSec>
        <Box>
          <SeduleMainSec>Don’t let your chores</SeduleMainSec>
          <SeduleMainSec>define who you are</SeduleMainSec>
          <SecondBoxSec>
            <WhileTextSec>Enter your address and schedule a collection today</WhileTextSec>
          </SecondBoxSec>
          <SecondBoxMain>
            <SeduleBoxText>Schedule your pickup</SeduleBoxText>
          </SecondBoxMain>
        </Box>
      </ThirdBoxSecFourFive>

    </>
  )
}

export default Services
