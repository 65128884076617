import React from 'react'

import { Box, Typography, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';



const ManiLastBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/23.jpg")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '480px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: '#fff',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('lg')]: {
        marginTop: '50px',
        height: '190px',

    }
}))

const MainBox = styled(Box)(({ theme }) => ({
    padding: '220px',
    [theme.breakpoints.down('lg')]: {
        padding: '65px',
    }
}))




const QuichText = styled(Typography)(({ theme }) => ({
    fontWeight: '600',
    fontSize: '45px',
    color: '#fffff',
    lineHeight: '70px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
        fontSize: '18px',
        lineHeight: '30px',
    }
}))

const ThirdBox = styled(Box)(({ theme }) => ({
    width: '73%',
    marginLeft: '210px',
    marginRight: '180px',
    marginTop: '30px',
    marginButtom: '30px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '50px',
        marginRight: '0px',
        marginTop: '30px',
        marginButtom: '0px',
    }
}))


const HowText = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    position: 'relative',
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'left',
    padding: '5px 0',

    [theme.breakpoints.down('lg')]: {
        fontSize: '17px',
        fontWeight: '600',
    }
}))


const PriceText = styled(Typography)(({ theme }) => ({
    paddingLeft: '20px',
    width: '100%',
    color: '#454545',
    [theme.breakpoints.down('lg')]: {
        fontSize: '16px',
        fontWeight: '500',
    }
}))








const HelpCenter = () => {
    return (
        <>

            <ManiLastBox>
                <MainBox>
                    <QuichText>"Your Help Center And Safety Is Our Priority"</QuichText>
                </MainBox>
            </ManiLastBox>


            <ThirdBox>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <HowText>How does Laundryheap work?</HowText>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceText>Everything is very easy! Simply select the required service and choose the dates for the collection</PriceText>
                        <PriceText>& delivery, leaving any instructions for the driver - we'll take care of everything else!</PriceText>
                    </AccordionDetails>
                </Accordion>
            </ThirdBox>
            <ThirdBox>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <HowText>What services does Laundryheap provider?</HowText>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceText>Laundryheap provides different services.</PriceText>
                        <PriceText>Laundry service - the items of clothing are machine washed at 30 degrees and tumble dried at a</PriceText>
                        <PriceText>medium temperature, then packed together.</PriceText>
                        <PriceText>Dry cleaning & ironed laundry - the items are processed individually, either dry cleaned or washed,</PriceText>
                        <PriceText>then ironed and placed on hangers for you to wear immediately.</PriceText>
                        <PriceText>Ironing only - you can send us your clean clothes and we can iron them for you. Please have in</PriceText>
                        <PriceText>mind that ironing will not remove all wrinkles if the clothes are extremely creased. If this is the</PriceText>
                        <PriceText>case, we strongly recommend opting for a "Dry cleaning & ironed laundry" service.</PriceText>

                        <PriceText>Bedding - Items such duvets, pillows, blankets, etc, will require a 72h processing time and they will</PriceText>
                        <PriceText>be priced individually.</PriceText>
                        <PriceText>Please note that some services might be available only in certain areas, so please check our</PriceText>
                        <PriceText>website for more details.</PriceText>
                    </AccordionDetails>

                </Accordion>
            </ThirdBox>
            <ThirdBox>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <HowText>What locations do you service?</HowText>
                    </AccordionSummary>
                    <PriceText>We are available in the following cities:</PriceText>
                    <AccordionDetails>
                        <PriceText>London, UK</PriceText>
                        <PriceText>Manchester, UK</PriceText>
                        <PriceText>Birmingham, UK</PriceText>
                        <PriceText>Coventry, UK</PriceText>
                        <PriceText>Dublin, IE</PriceText>
                        <PriceText>Amsterdam, NL</PriceText>
                        <PriceText>Rotterdam, NL</PriceText>
                        <PriceText>The Hague, NL</PriceText>
                        <PriceText>Stockholm, SE</PriceText>
                        <PriceText>Copenhagen, DK</PriceText>
                        <PriceText>Dubai, AE</PriceText>
                        <PriceText>Sharjah, AE</PriceText>
                        <PriceText>Abu Dhabi, AE</PriceText>
                        <PriceText>Doha, QA</PriceText>
                        <PriceText>Kuwait City, KW</PriceText>
                        <PriceText>Manama, BH</PriceText>
                        <PriceText>Singapore, SG</PriceText>
                        <PriceText>New York, US</PriceText>
                        <PriceText>Miami, US</PriceText>
                        <PriceText>Dallas, US</PriceText>
                        <PriceText>Washington D.C., US</PriceText>
                        <PriceText>Boston, US</PriceText>
                        <PriceText>Chicago, US</PriceText>
                        <PriceText>Los Angeles, US</PriceText>
                        <PriceText>San Francisco, US</PriceText>
                        <PriceText>San Jose, US</PriceText>
                    </AccordionDetails>
                </Accordion>
            </ThirdBox>

            <ThirdBox>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <HowText>What is the turnaround time?</HowText>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceText>You will be happy to know that last month we have delivered 98.7% of all standard laundry and</PriceText>
                        <PriceText>dry-cleaning within 24 hours. We will always do our best to let you know in time if you included</PriceText>
                        <PriceText>items that require a longer time to process, or if there are any delivery changes in your order.</PriceText>
                    </AccordionDetails>
                </Accordion>
            </ThirdBox>

            <ThirdBox>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <HowText>What are the Eco-Friendly Routes?</HowText>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceText>The Eco-Friendly Routes give you the possibility to contribute to the well-being of the</PriceText>

                        <PriceText>environment. Choose them and allow us to collect and deliver in wider time slots, so we can reduce</PriceText>

                        <PriceText>the fuel used by grouping the orders together.</PriceText>

                    </AccordionDetails>
                </Accordion>
            </ThirdBox>

            <ThirdBox>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <HowText>Where do you clean my clothing?</HowText>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceText>After your items are collected by our driver, they are taken to one of our local partner facilities.</PriceText>

                        <PriceText>Each order is processed separately - Laundry orders are weighed and cleaned, while all other</PriceText>

                        <PriceText>services are individually itemised and processed. We take pride in supporting local businesses and</PriceText>

                        <PriceText>minimizing the carbon emissions from transport.</PriceText>
                    </AccordionDetails>
                </Accordion>
            </ThirdBox>

            <ThirdBox>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <HowText>I am allergic to certain types of detergent. What are my options?</HowText>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceText>Your safety is very important to us and we would like to offer you the possibility of choosing</PriceText>

                        <PriceText>between bio and non-bio detergent. Just make sure to let us know as soon as possible so we can</PriceText>

                        <PriceText>accommodate it for you.</PriceText>

                        
                    </AccordionDetails>
                </Accordion>
            </ThirdBox>

            <ThirdBox>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <HowText>Can i provide my own detergent</HowText>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceText>At this time, we do not offer the option for customers to provide their own laundry detergent, but</PriceText>

                        <PriceText>please let us know if you are allergic to a certain type so we can make sure to avoid it.</PriceText>

                    </AccordionDetails>
                </Accordion>
            </ThirdBox>

            <ThirdBox>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <HowText>Do i need to separate the laundry and dry cleaning items?</HowText>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceText>Yes, please do. This will ensure everything will be processed as per your requirements. We strongly</PriceText>

                        <PriceText>advise using a separate bag for each type of service you require (laundry, dry cleaning, ironing, etc).</PriceText>

                        
                    </AccordionDetails>
                </Accordion>
            </ThirdBox>

            <ThirdBox>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <HowText>How can you ensure my items won't be damaged?</HowText>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceText>Your items are handled with maximum care and up-to-date equipment. In the extremely rare case</PriceText>

                        <PriceText>where a damage occurs, it will be covered by our insurance in accordance to our Terms and</PriceText>

                        <PriceText>Conditions. Please have in mind that all claims must be made within 48 hours after delivery.</PriceText>

                        
                    </AccordionDetails>
                </Accordion>
            </ThirdBox>

            <ThirdBox>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <HowText>Do you wash my clothes together with other people's clothes?</HowText>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceText>Absolutely not. Each order is washed separately so no need to worry about that. Your clothes are</PriceText>

                        <PriceText>safe with us!</PriceText>

                    </AccordionDetails>
                </Accordion>
            </ThirdBox>
            <ThirdBox>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <HowText>Do you offer contactless delivery?</HowText>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceText>For the safety of you and our partners, Laundryheap now offers contactless delivery. Should you</PriceText>

                        <PriceText>prefer, you can select for a contactless collection or delivery and our driver will collect or deliver</PriceText>

                        <PriceText>your items from a location of your choice. Alternatively, you can still meet one of our trusted drivers</PriceText>

                        <PriceText>at your door where they will ensure to remain at least 2 meters or more away from you.</PriceText>

                        <PriceText>To have a contactless delivery, please provide instructions of where we should collect or drop off</PriceText>

                        <PriceText>your items in the special delivery instructions field when you place your order. If you have any</PriceText>

                        <PriceText>concerns or questions, please reach out to us at help@laundryheap.com</PriceText>

                        
                    </AccordionDetails>
                </Accordion>
            </ThirdBox>

            <ThirdBox>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <HowText>Do you clean wedding dresses?</HowText>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceText>We might be able to help you with that! Just get in touch with our team by sending an email to</PriceText>

                        <PriceText>help@laundryheap.com or by writing to us on live chat, directly in our app, and send us a picture of</PriceText>

                        <PriceText>the dress. As wedding dresses are extremely delicate, the price will vary depending on size, fabric</PriceText>

                        <PriceText>and embroidment. We'll check with our cleaning partners and confirm if we can help and at what cost.</PriceText>

                        
                    </AccordionDetails>
                </Accordion>
            </ThirdBox>

           

        </ >
    )
}

export default HelpCenter
