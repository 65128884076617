import React from 'react'
import { Box, styled, Typography } from '@mui/material'

const MainBoxFild = styled(Box)(({theme})=> ({
    display: 'flex',
    marginTop: '40px',
    marginBottom: '40px',
    [theme.breakpoints.down('lg')]:{
        display: 'block',
        marginTop: '-160px',
    }
}))



const SecondMainBox = styled(Box)(({theme})=> ({
    marginLeft: '62px',
    marginBottom: '10px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '10px',
        marginBottom: '0px',
    }
}))





const ServiceTextPerFact = styled(Typography)(({theme})=> ({
    fontSize: '42px',
    fontWeight: '600',
    color: '#305349',
    marginLeft: '150px',
    marginTop: '20px',
    lineHeight: '35px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '22px',
        textAlign: 'center',
        marginLeft: '5px',
        marginTop: '160px',
        lineHeight: '15px', 
    }
}))



const SecondMainFild = styled(Box)(({theme})=> ({
    width: '200px',
    height: '50px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 4px 2px #d9d9d9',
    display: 'flex',
    borderRadius: '6px',
    marginLeft: '523px',
    marginTop: '15px',
    [theme.breakpoints.down('lg')]: {
        display: 'Block',
        marginLeft: '98px',
        marginTop: '50px',
        marginBottom: '50px'
    }
}))



const HomeBox = styled(Box)`
    width: 185px;
    height: 40px;
   
    border-radius: 6px;
    margin-top: 5px;
    margin-left: 7px;

`;



const HomeFild = styled(Typography)(({theme})=> ({
    fontSize: '18px',
    fontWeight: '600',
    color: 'black',
    textAlign: 'center',
    marginTop: '6px',
    [theme.breakpoints.down('lg')]: {
        padding: '12px',
    }
}))




const SecondServicesBox = styled(Box)(({theme})=> ({
    display: 'flex',
    marginLeft: '205px',
    marginTop: '50px',
    marginBottom: '40px',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
        marginLeft: '54px',
        marginTop: '50px',
     
    }

}))

const StyleBox = styled(Box)`
    width: 262px;
    height: 370px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 2px #d9d9d9;
    margin: 10px;
`

const ImageWashing = styled('img')({
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    marginLeft: '91px',
    marginTop: '25px',
})

const PulmbingText = styled(Typography)`
    font-size: 16px;
    font-weight: 600;
    color: #305349;
    margin-top: 15px;
    text-align: center;
    letter-spacing : 1px;
    
`;

const PulmbingTextSec = styled(Typography)`
    font-size: 13px;
    font-weight: 600;
    color: #305349;
    letter-spacing : 1px;
    text-align: center;
    margin-top: -5px;
    
`;

const YouMainBox = styled(Box)`
    width: 240px;
    border-radius: 7px;
    height: 120px;
    background: #EAEAEA;
    margin-left: 11px;
`

const MainTextYou = styled(Typography)`
    font-size: 16px;
    font-weight: 600;
    color: #305349;
    text-align: center;
    margin-top: 10px;
    padding: 25px;
`;

const TextYou = styled(Typography)`
    font-size: 13px;
    font-weight: 500;
    color: #305349;
    text-align: center;
    margin-top: -10px;
    padding: -20px;
`;

const LearnBox = styled(Box)`
    width: 160px;
    height: 40px;
    background: #305349;
    border-radius: 5px;
    margin-left: 54px;
    margin-top: 20px;
`;

const MoreText = styled(Typography)`
    color: white;
    font-weight: 600;
    text-align: center;
    padding: 8px;
`


const NineHomePage = () => {
    return (
        <>
            <MainBoxFild>
                <SecondMainBox>

                    <ServiceTextPerFact>Meet wassing team</ServiceTextPerFact>

                </SecondMainBox>

                <SecondMainFild>
                    <HomeBox>
                        <HomeFild>Explore More</HomeFild>
                    </HomeBox>
                </SecondMainFild>
            </MainBoxFild>

            <SecondServicesBox>
                <StyleBox>
                    <ImageWashing src="https://www.imagediamond.com/blog/wp-content/uploads/2019/11/attratve-Boy-DP4.jpg" alt="" />
                    <PulmbingText>Alex Andi</PulmbingText>
                    <PulmbingTextSec>Handyman</PulmbingTextSec>
                    <YouMainBox>
                        <MainTextYou>LATEST REVIEW</MainTextYou>
                        <TextYou>"We contacted the company for the cleaning after the repair."</TextYou>
                    </YouMainBox>
                    <LearnBox>
                        <MoreText>Request Quote</MoreText>
                    </LearnBox>
                </StyleBox>

                <StyleBox>
                    <ImageWashing src="https://st2.depositphotos.com/2056297/9723/i/950/depositphotos_97236866-stock-photo-handsome-sexy-male-model-posing.jpg" alt="" />
                    <PulmbingText>Phills Will</PulmbingText>
                    <PulmbingTextSec>Cleaner</PulmbingTextSec>
                    <YouMainBox>
                        <MainTextYou>LATEST REVIEW</MainTextYou>
                        <TextYou>"We contacted the company for the cleaning after the repair."</TextYou>
                    </YouMainBox>

                    <LearnBox>
                        <MoreText>Request Quote</MoreText>
                    </LearnBox>
                </StyleBox>

                <StyleBox>
                    <ImageWashing src="https://i.pinimg.com/564x/a5/9b/8c/a59b8c0c50f181e5bde6a76eff60abea--boy-fashion-allah.jpg" alt="" />
                    <PulmbingText>Wiliam Andy</PulmbingText>
                    <PulmbingTextSec>Plumber</PulmbingTextSec>
                    <YouMainBox>
                        <MainTextYou>LATEST REVIEW</MainTextYou>
                        <TextYou>"We contacted the company for the cleaning after the repair."</TextYou>
                    </YouMainBox>
                    <LearnBox>
                        <MoreText>Request Quote</MoreText>
                    </LearnBox>
                </StyleBox>

                <StyleBox>
                    <ImageWashing src="https://www.whatsappimages.in/wp-content/uploads/2022/01/Girl-DP.jpg" alt="" />
                    <PulmbingText>Amelia Bright</PulmbingText>
                    <PulmbingTextSec>Handyman</PulmbingTextSec>
                    <YouMainBox>
                        <MainTextYou>LATEST REVIEW</MainTextYou>
                        <TextYou>"We contacted the company for the cleaning after the repair."</TextYou>
                    </YouMainBox>
                    <LearnBox>
                        <MoreText>Request Quote</MoreText>
                    </LearnBox>
                </StyleBox>
            </SecondServicesBox>
        </>
    )
}

export default NineHomePage
