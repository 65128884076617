import { Box, Typography, styled, Button } from '@mui/material';
import React from 'react';


const ManiLastBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/23.jpg")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '480px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: '#fff',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('lg')]: {
        marginTop: '50px',
        height: '290px',

    }
}))

const MainBox = styled(Box)(({ theme }) => ({
    padding: '220px',
    [theme.breakpoints.down('lg')]: {
        padding: '55px',
    }
}))




const QuichText = styled(Typography)(({ theme }) => ({
    fontWeight: '600',
    fontSize: '62px',
    color: '#fffff',
    lineHeight: '70px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
        fontSize: '28px',
        lineHeight: '80px',
    }
}))


const FirstMain = styled(Box)(({ theme }) => ({
    display: 'flex',
    margin: 'auto',
    marginTop: '20px',
    marginBottom: '50px',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
    }
}))




const MainHeaderText = styled(Box)(({ theme }) => ({
    marginLeft: '210px',
    marginTop: '100px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '0',
        marginTop: '0',
    }
}))



const FirstText = styled(Typography)(({ theme }) => ({
    fontWeight: '600',
    fontSize: '42px',
    color: '#305349',
    lineHeight: '70px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '25px',
        lineHeight: '35px',
        marginLeft: '5px',
        textAlign: 'center',
    }
}))



const SecondBox = styled(Box)(({ theme }) => ({
    marginTop: '20px',
    width: '80%',
    [theme.breakpoints.down('lg')]: {
        marginTop: '20px',
        marginLeft: '78px',
        textAlign: 'center',
        width: '60%',
    }

}))



const WhileText = styled(Typography)(({ theme }) => ({
    color: 'grey',
    lineHeight: '20px',
    fontWeight: '500',
    [theme.breakpoints.down('lg')]: {

    }
}))


const ImageHeader = styled('img')(({ theme }) => ({
    widht: '150px',
    height: '450px',
    marginTop: '30px',
    marginLeft: '5px',
    [theme.breakpoints.down('lg')]: {
        width: '300px',
        height: '290px',
        marginLeft: '54px',
        marginTop: '10px',
    }
}))


const FirstMainBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '130px',
    background: '#305349',
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        display: 'block',
        background: '#305349',
        overflow: 'hidden',
        height: '540px',
    }
}))



const ImageIcon = styled('img')(({ theme }) => ({
    width: '320px',
    height: '150px',
    margin: '0px',
    marginLeft: '120px',
    marginTop: '-10px',
    [theme.breakpoints.down('lg')]: {
        width: '410px',
        height: '200px',
        marginLeft: '0px',

        marginTop: '-30px',
    }
}))



const ImageIconSec = styled('img')(({ theme }) => ({
    width: '270px',
    height: '150px',
    margin: '0px',
    marginLeft: '-40px',
    marginTop: '-10px',
    [theme.breakpoints.down('lg')]: {
        width: '410px',
        height: '200px',
        marginLeft: '0px',
        marginTop: '-100px',

    }
}))




const ImageIconThird = styled('img')(({ theme }) => ({
    width: '254px',
    height: '150px',
    margin: '0px',
    marginLeft: '-10px',
    marginTop: '-10px',
    [theme.breakpoints.down('lg')]: {
        width: '410px',
        height: '200px',
        marginLeft: '0px',
        marginTop: '-100px',
    }
}))




const ThirdBoxSec = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: '85px',
    marginBottom: '35px',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
        marginTop: '0px',
        marginBottom: '0px',
    }
}))



const ImageBoxSec = styled(Box)(({ theme }) => ({
    marginLeft: '217px',
    marginTop: '-30px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '47px',
        marginTop: '30px',
    }
}))




const SeduleMainSec = styled(Typography)(({ theme }) => ({
    fontSize: '28px',
    fontWeight: '600',
    color: '#305349',
    marginLeft: '95px',
    marginTop: '-5px',
    lineHeight: '25px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '22px',
        textAlign: 'center',
        marginLeft: '5px',
        marginTop: '25px',
        lineHeight: '15px',

    }
}))

const SeduleMainSecBox = styled(Typography)(({ theme }) => ({
    fontSize: '18px',
    fontWeight: '600',
    color: '#305349',
    marginLeft: '95px',
    marginTop: '20px',
    lineHeight: '25px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '22px',
        textAlign: 'center',
        marginLeft: '5px',
        marginTop: '25px',
        lineHeight: '25px',

    }
}))

const SecondBoxSec = styled(Box)(({ theme }) => ({
    marginTop: '25px',
    marginLeft: '95px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '20px',
        marginLeft: '75px',
        textAlign: 'center',
        width: '60%',
    }

}))

const SecondBoxSecBox = styled(Box)(({ theme }) => ({
    marginTop: '25px',
    marginLeft: '95px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '20px',
        marginLeft: '75px',
        textAlign: 'center',
        width: '80%',
    }

}))

const WhileTextSec = styled(Typography)(({ theme }) => ({
    color: 'grey',
    lineHeight: '20px',
    fontWeight: '500',
    width: '82%',
    [theme.breakpoints.down('lg')]: {

    }
}))


const WhileTextSecBox = styled(Typography)(({ theme }) => ({
    color: 'grey',
    lineHeight: '20px',
    width: '82%',
    [theme.breakpoints.down('lg')]: {

    }
}))




const ImageFlexSec = styled('img')(({ theme }) => ({
    width: '550px',
    height: '400px',
    [theme.breakpoints.down('lg')]: {
        width: '300px',
        height: '250px',
        marginTop: '25px',

    }
}))


const TextBox = styled(Box)`
    margin-top: 85px;
    margin-bottom: 50px;
`;

const WeAreText = styled(Typography)`
    font-size: 28px;
    font-weight: 600;
    color: #305349;
    text-align: center;

`;



const TextBoxSec = styled(Box)`  
    margin-bottom: 50px;
`;

const AmazingText = styled(Typography)`
    color: grey;
    line-height: 25px;
    font-weight: 500;
    text-align: center;
`;

const FounderText = styled(Typography)`
    text-align: center;
    color: grey;
    margin-top: 16px;
    font-weight: 600;
`

const MainSecBox = styled(Box)(({theme})=> ({
    width: '100%',
    height: '900px',
    background: '#202B93',
    marginBottom: '50px',
    [theme.breakpoints.down('lg')]:{
        height: '650px',
    } 
}))
   


const ImageBox = styled('img')(({theme})=> ({
    marginLeft: '742px',
    marginTop: '67px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '180px',
        marginTop: '60px',
    }
}))



const WeAreTextSec = styled(Typography)`
    font-size: 28px;
    font-weight: 600;
    color: white;
    text-align: center;
    margin-top: 60px;
`;



const AmazingTextSec = styled(Typography)`
    color: white;
    line-height: 25px;
    font-weight: 500;
    text-align: center;
`;

const FounderTextSec = styled(Typography)`
    text-align: center;
    color: white;
    margin-top: 16px;
    font-weight: 600;
`;

const ImageShop = styled('img')(({ theme }) => ({
    width: '1288px',
    height: '474px',
    marginTop: '62px',
    marginLeft: '120px',
    [theme.breakpoints.down('lg')]: {
        width: '375px',
        height: '170px',
        marginLeft: '4px',
    }
}))



const SeduleMainSecCom = styled(Typography)(({ theme }) => ({
    fontSize: '28px',
    fontWeight: '600',
    color: '#305349',
    marginLeft: '215px',
    marginTop: '25px',
    lineHeight: '15px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '20px',
        textAlign: 'center',
        marginLeft: '5px',

        lineHeight: '0px',

    }
}))

const SeduleMainSecBoxCom = styled(Typography)(({ theme }) => ({
    fontSize: '18px',
    fontWeight: '600',
    color: '#305349',
    marginLeft: '215px',
    marginTop: '20px',
    lineHeight: '25px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '22px',
        textAlign: 'center',
        marginLeft: '5px',
        marginTop: '25px',
        lineHeight: '40px',

    }
}))

const SecondBoxSecBoxCom = styled(Box)(({ theme }) => ({
    marginTop: '25px',
    marginLeft: '215px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '20px',
        marginLeft: '75px',
        textAlign: 'center',
        width: '60%',
    }

}))

const WhileTextSecBoxCom = styled(Typography)(({ theme }) => ({
    color: 'grey',
    lineHeight: '20px',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
    }
}))


const ImageBoxSecCom = styled(Box)(({ theme }) => ({
    marginLeft: '145px',
    marginTop: '-30px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '105px',
        marginTop: '30px',
    }
}))

const ImageFlexSecCom = styled('img')(({ theme }) => ({
    width: '290px',
    height: '480px',
    [theme.breakpoints.down('lg')]: {
        width: '190px',
        height: '290px',

    }
}))

const FourBox = styled(Box)`
    margin-top: 65px;
`;

const MainHeadBox = styled(Box)(({theme})=> ({
    marginTop: '48px',
    width: '42%',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginTop: '38px',
    }

}))



const WantBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '310px',
    background: 'rgb(247,250,252)',
    marginTop: '60px',
    [theme.breakpoints.down('lg')]: {
        height: '650px',
    }
}))



const WnatTextSec = styled(Typography)(({theme})=> ({
    fontSize: '18px',
    color: '#305349',
    fontWeight: '600',
    marginLeft: '165px',
    padding: '50px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '18px',
        textAlign: 'center',
        marginLeft: '0px',
    }
}))



const WnatText = styled(Typography)(({theme})=> ({
    fontSize: '18px',
    color: '#305349',
    fontWeight: '600',
    marginLeft: '215px',
    marginTop: '-25px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '18px',
        textAlign: 'center',
        marginLeft: '0px',
        marginTop: '0px',
    }
  
}))




const SecondMainu = styled(Box)(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
    }
}))



const WantTextHeader = styled(Typography)(({theme})=> ({
    color: 'grey',
    marginTop: '25px',
    marginLeft: '215px',
    lineHeight: '10px',
    [theme.breakpoints.down('lg')]: {
       
        textAlign: 'center',
        marginLeft: '0px',
       
    }
}))


const BootomBox = styled(Box)(({theme})=> ({
    marginTop: '25px',
    marginLeft: '215px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '25px',
        textAlign: 'center',
        marginLeft: '0px',
    }
}))




const AboutPage = () => {
    return (
        <>
            <ManiLastBox>
                <MainBox>
                    <QuichText>"Your Cloth Safety Is Our Priority"</QuichText>
                </MainBox>
            </ManiLastBox>

            <FirstMain>
                <MainHeaderText>
                    <FirstText>Reinventing the</FirstText>
                    <FirstText>future of laundry and</FirstText>
                    <FirstText>dry cleaning</FirstText>
                    <SecondBox>
                        <WhileText>Laundryheap delivers the quickest, easiest to use, and most reliable professional</WhileText>
                        <WhileText>laundry and dry cleaning service that completely adjusts to your needs.</WhileText>
                    </SecondBox>
                </MainHeaderText>
                <ImageHeader src="https://prod-cdn.laundryheap.com/assets/landing/subpages/why_us/hero-e08d1e94ae40da2d91bb8f9fdcc02e33829c500be1dcf6a20fe369f72d7df345.jpg" alt="" />
            </FirstMain>

            <FirstMainBox>
                <ImageIcon src="Image/7.png" alt="" />
                <ImageIconSec src="Image/8.png" alt="" />
                <ImageIconThird src="Image/9.png" alt="" />
                <ImageIconThird src="Image/10.png" alt="" />
                <ImageIconThird src="Image/12.png" alt="" />
            </FirstMainBox>


            <ThirdBoxSec>
                <ImageBoxSec>
                    <ImageFlexSec src="Image/25.jpg" alt="" />
                </ImageBoxSec>
                <Box>

                    <SeduleMainSec>Who we are</SeduleMainSec>
                    <SecondBoxSec>
                        <WhileTextSec>Founded in 2014 in London, Laundryheap is the next generation laundry & dry</WhileTextSec>
                        <WhileTextSec>cleaning company. We offer professional laundry and dry cleaning delivered to</WhileTextSec>
                        <WhileTextSec>your doorstep in as quick as 24 hours.</WhileTextSec>
                    </SecondBoxSec>
                    <SeduleMainSecBox>We are available globally</SeduleMainSecBox>
                    <SecondBoxSecBox>
                        <WhileTextSecBox>Since our beginnings in London, we have expanded globally to 11 countries. Our</WhileTextSecBox>
                        <WhileTextSecBox>services are available in the following countries: Netherlands, Ireland, UAE, Bahrain,</WhileTextSecBox>
                        <WhileTextSecBox>Singapore, Kuwait, Qatar, Sweden, United Kingdom, United States, and Denmark.</WhileTextSecBox>
                    </SecondBoxSecBox>
                </Box>
            </ThirdBoxSec>

            <Box>
                <TextBox>
                    <WeAreText>We’re investing into one of the largest fleets of</WeAreText>
                    <WeAreText>electric delivery vehicles.</WeAreText>
                </TextBox>

                <TextBoxSec>
                    <WeAreText>"</WeAreText>
                    <AmazingText>Amazing! That will become the single largest fleet of trikes/quadricycles in operation! So positive to witness a</AmazingText>
                    <AmazingText>shift from token orders from companies (look good) to full commercial roll out.</AmazingText>
                    <FounderText>FOUNDER OF GREENAER</FounderText>
                </TextBoxSec>
            </Box>

            <Box>
                <MainSecBox>
                    <Box>
                        <ImageBox src="https://prod-cdn.laundryheap.com/assets/landing/subpages/why_us/icons/quote-de34a94de3cdf73d4b1b23111397b8bde0dacf5796643dd6b8c3bfed2285a25d.svg" alt="" />

                    </Box>
                    <WeAreTextSec>Meet the team</WeAreTextSec>
                    <AmazingTextSec>We’re a global team of laundry, tech, and logistics experts on a mission to free people from laundry so that they</AmazingTextSec>
                    <AmazingTextSec>can spend more time doing what they</AmazingTextSec>
                    <FounderTextSec>TEAM LAUNDRYHEAP</FounderTextSec>

                    <ImageShop src="https://prod-cdn.laundryheap.com/assets/landing/subpages/team-827db5112a277d49ad98dbbe505158a4d6dd1bf246f22845ad7fb0351ff4c73a.png" alt="" />
                </MainSecBox>
            </Box>


            <ThirdBoxSec>

                <FourBox>

                    <SeduleMainSecCom>Committed to making things easy for</SeduleMainSecCom>
                    <SeduleMainSecCom>you</SeduleMainSecCom>
                    <SeduleMainSecBoxCom>Managing your laundry and dry cleaning in the 21st century should be simple</SeduleMainSecBoxCom>
                    <SeduleMainSecBoxCom>and accessible from anywhere.</SeduleMainSecBoxCom>
                    <SecondBoxSecBoxCom>
                        <WhileTextSecBoxCom>We created the app that allows you to schedule an order in less than 2 minutes,</WhileTextSecBoxCom>
                        <WhileTextSecBoxCom>whether at home, at the office or on the go. No need to speak to any,</WhileTextSecBoxCom>
                        <WhileTextSecBoxCom>representative, you can now manage all your orders with our easy-to-use website</WhileTextSecBoxCom>
                        <WhileTextSecBoxCom>or mobile app.</WhileTextSecBoxCom>
                    </SecondBoxSecBoxCom>
                </FourBox>

                <ImageBoxSecCom>
                    <ImageFlexSecCom src="Image/26.png" alt="" />
                </ImageBoxSecCom>
            </ThirdBoxSec>


            <ThirdBoxSec>
                <ImageBoxSec>
                    <ImageFlexSec src="Image/27.jpg" alt="" />
                </ImageBoxSec>

                <MainHeadBox>
                    <SeduleMainSec>Quality without compromise</SeduleMainSec>

                    <SeduleMainSecBox>We partner with carefully-selected local cleaning and delivery partners to</SeduleMainSecBox>
                    <SeduleMainSecBox>ensure your items are treated with the utmost care.</SeduleMainSecBox>
                    <SecondBoxSecBox>
                        <WhileTextSecBox>We never compromise when it comes to quality and speed. We compensate our</WhileTextSecBox>
                        <WhileTextSecBox>partner drivers fairly and are proud to work with dozens of reliable local cleaning</WhileTextSecBox>
                        <WhileTextSecBox>facilities to keep transport to a minimum.</WhileTextSecBox>
                    </SecondBoxSecBox>
                </MainHeadBox>
            </ThirdBoxSec>


            <Box>
                <WantBox>
                    <WnatTextSec>Want to work with us?</WnatTextSec>
                    <SecondMainu>
                        <Box>
                            <WnatText>Careers</WnatText>
                            <WantTextHeader>Want to work with us? Check open</WantTextHeader>
                            <WantTextHeader>positions</WantTextHeader>
                            <BootomBox>
                                <Button>Learn More</Button>
                            </BootomBox>

                        </Box>

                        <Box>
                            <WnatText>Cleaning partners</WnatText>
                            <WantTextHeader>Want to grow your customer base</WantTextHeader>
                            <WantTextHeader>completely hassle free?</WantTextHeader>
                            <BootomBox>
                                <Button>Learn More</Button>
                            </BootomBox>

                        </Box>

                        <Box>
                            <WnatText>Partner drivers</WnatText>
                            <WantTextHeader>Get flexible slots and fair, competitive</WantTextHeader>
                            <WantTextHeader>fees.</WantTextHeader>
                            <BootomBox>
                                <Button>Learn More</Button>
                            </BootomBox>

                        </Box>
                    </SecondMainu>
                </WantBox>


            </Box>


        </>
    )
}

export default AboutPage
