import React from 'react'
import { Box, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';



const ManiLastBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/23.jpg")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '480px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: '#fff',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('lg')]: {
        marginTop: '50px',
        height: '290px',

    }
}))

const MainBox = styled(Box)(({ theme }) => ({
    padding: '220px',
    [theme.breakpoints.down('lg')]: {
        padding: '55px',
    }
}))




const QuichText = styled(Typography)(({ theme }) => ({
    fontWeight: '600',
    fontSize: '62px',
    color: '#fffff',
    lineHeight: '70px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
        fontSize: '28px',
        lineHeight: '80px',
    }
}))


const MainLast = styled(Box)`
  display: flex;
`;

const TextBox = styled(Box)`
  margin-left: 215px;
  margin-top: 100px;
  margin-bottom: 50px;
`

const TextFind = styled(Typography)`
  font-weight: 600;      
  font-size: 42px;
  color: #305349;
`;



const MainThBox = styled(Box)(({theme})=> ({
    width: '350px',
    height: '540px',
    border: '1px solid #305349',
    overflow: 'hidden',
    borderRadius: '4px',
    marginTop: '50px',
    marginBottom: '55px',
    marginLeft: '265px',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '20px',
    }
  
  }))
   
  
  const NextBox = styled(Box)`
  width: 325px;
  height: 50px;
  background: #305349;
  border-radius: 5px;
  border: 1px solid green;
  color: white;
  margin-left: 12px;
  margin-top: 12px;
`;
  
  
  const NextText = styled(Typography)`
      text-align: center;
      margin-top: 10px;
      font-size: 18px;
      letter-spacing: 2px;
  `
  
  const AdressBox = styled(Box)`
      display: flex;
      margin-left: 40px;
      margin-top: 5px;
  `
  const DoneIcon = styled(Box)`
      margin-left: 0px;
      margin-top: 10px;
  `
  
  const AddressText = styled(Typography)`
      margin-left: 20px;
      margin-top: 12px;
      color: #305349;
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
      letter-spacing: 1px;
  `

const HowText = styled(Typography)`
    color: #305349;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
`;

const WeeNeedText = styled(Typography)`
    color: grey;
    font-size: 15px;
    margin-top: 10px;
`

const ChekBox = styled(Box)`
    margin-left: -10px;
`

const FormBox = styled(Box)`
    margin-top: 20px;
`





const PaymentGatewat = () => {
    return (
        <>
            <ManiLastBox>
                <MainBox>
                    <QuichText>"Booking Laundry"</QuichText>
                </MainBox>
            </ManiLastBox>


            <MainLast>
                <TextBox>
                    <TextFind>Payment Mode</TextFind>

                    <HowText>How can we contact you?</HowText>
                    <WeeNeedText>We need your contact information to keep you updated about your order.</WeeNeedText>

                    <ChekBox>
                        <div className='row mt-3'>
                            <div class="form-check">
                                <input class="form-check-input mt-2 ml-1" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                <label class="form-check-label ml-4" for="exampleRadios1">
                                    Individual
                                </label>
                            </div>

                            <div class="form-check">
                                <Link to='/company' style={{ color: 'grey' }}>
                                    <input class="form-check-input mt-2 ml-1" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" />
                                    <label class="form-check-label ml-4" for="exampleRadios2">
                                        Company
                                    </label>
                                </Link>
                            </div>
                        </div>
                    </ChekBox>

                    <FormBox>


                        <form>
                            <div class="row">
                                <div class="col">
                                    <label >First Name</label>
                                    <input type="text" class="form-control" placeholder="First name" />
                                </div>
                                <div class="col">
                                    <label >Last Name</label>
                                    <input type="text" class="form-control" placeholder="Last name" />
                                </div>
                            </div>
                        </form>

                        <div class="form-outline mt-2">
                            <label class="form-label" for="typeNumber">Phone Number</label>
                            <input type="number" id="typeNumber" class="form-control" />
                        </div>

                        <div class="form-group mt-2">
                            <label for="exampleInputEmail1">Email address</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />

                        </div>

                    </FormBox>


                </TextBox>

                <MainThBox>
                    <Link to='#' style={{ textDecoration: 'none' }} >
                        <NextBox>
                            <NextText>Next</NextText>
                        </NextBox>
                    </Link>

                    <hr/>

                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>

                        <Link to='/booking' style={{ textDecoration: 'none' }}>
                            <AddressText>Address</AddressText>
                        </Link>
                    </AdressBox>

                    <hr/>

                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>
                        <Link to='/bookingcollection' style={{ textDecoration: 'none' }}>
                            <AddressText>Collection time</AddressText>
                        </Link>
                    </AdressBox>


                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>
                        <Link to='/bookingcollection' style={{ textDecoration: 'none' }}>
                            <AddressText>Delivery time</AddressText>
                        </Link>
                    </AdressBox>


                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>
                        <Link to='/bookingcollection' style={{ textDecoration: 'none' }}>
                            <AddressText>Frequency</AddressText>
                        </Link>
                    </AdressBox>

                    <hr/>


                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>
                        <Link to='/selectedservice' style={{ textDecoration: 'none' }}>
                            <AddressText>Selected services</AddressText>
                        </Link>
                    </AdressBox>

                    <hr/>


                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>
                        <Link to='/contectus' style={{ textDecoration: 'none' }}>
                            <AddressText>Contact</AddressText>
                        </Link>
                    </AdressBox>

                    <hr/>


                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>
                        <Link to='/payment' style={{ textDecoration: 'none' }}>
                            <AddressText>Payment</AddressText>
                        </Link>
                    </AdressBox>

                </MainThBox>

            </MainLast>
        </>
    )
}

export default PaymentGatewat
