import React from 'react'
import { Box, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';


const ManiLastBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/23.jpg")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '480px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: '#fff',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('lg')]: {
        marginTop: '50px',
        height: '180px',

    }
}))

const MainBox = styled(Box)(({ theme }) => ({
    padding: '220px',
    [theme.breakpoints.down('lg')]: {
        padding: '55px',
    }
}))




const QuichText = styled(Typography)(({ theme }) => ({
    fontWeight: '600',
    fontSize: '62px',
    color: '#fffff',
    lineHeight: '70px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
        fontSize: '28px',
        lineHeight: '80px',
    }
}))


const MainLast = styled(Box)(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
        display: 'block'
    }
}))

const TextBox = styled(Box)(({ theme }) => ({
    marginLeft: '215px',
    marginTop: '50px',
    marginBottom: '50px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '0px',
    }
}))



const TextFind = styled(Typography)(({ theme }) => ({
    fontWeight: '600',
    fontSize: '42px',
    color: '#305349',
    [theme.breakpoints.down('lg')]: {
        fontSize: '22px',
        textAlign: 'center'
    }
}))



const FormText = styled(Box)(({ theme }) => ({
    marginTop: '15px',
    width: '580px',
    height: '150px',
    border: '1px solid #305349',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        height: '300px',
        border: '0px solid #305349',
    }
}))




const MainThBox = styled(Box)(({ theme }) => ({
    width: '350px',
    height: '940px',
    border: '1px solid #305349',
    overflow: 'hidden',
    borderRadius: '4px',
    marginTop: '165px',
    marginBottom: '55px',
    marginLeft: '178px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '20px',
        marginTop: '-10px',
        marginBottom: '55px',
    }

}))


const NextBox = styled(Box)`
  width: 325px;
  height: 50px;
  background: #305349;
  border-radius: 5px;
  border: 1px solid green;
  color: white;
  margin-left: 12px;
  margin-top: 12px;
`;

const NextText = styled(Typography)`
      text-align: center;
      margin-top: 10px;
      font-size: 18px;
      letter-spacing: 2px;
  `

const AdressBox = styled(Box)`
      display: flex;
      margin-left: 40px;
      margin-top: 5px;
  `
const DoneIcon = styled(Box)`
      margin-left: 0px;
      margin-top: 10px;
  `

const AddressText = styled(Typography)`
      margin-left: 20px;
      margin-top: 12px;
      color: #305349;
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
      letter-spacing: 1px;
  `



const IconBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginLeft: '12px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '100px',
        display: 'block',
        marginTop: '-30px',
    }

}))


const ImageIcon = styled('img')(({ theme }) => ({
    width: '40px',
    height: '40px',
    marginLeft: '30px',
    marginTop: '52px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '60px',
        width: '60px',
        height: '60px',
    }
}))



const WashText = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: '600',
    marginLeft: '22px',
    marginTop: '25px',
    color: '#305349',
    letterSpacing: '1px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '70px',
        marginTop: '18px',
    }
}))

const WashTextSec = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: '600',
    marginLeft: '22px',
    marginTop: '25px',
    color: '#305349',
    letterSpacing: '1px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '35px',
        marginTop: '18px',
    }
}))

const WashTextTh = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: '600',
    marginLeft: '22px',
    marginTop: '25px',
    color: '#305349',
    letterSpacing: '1px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '33px',
        marginTop: '18px',
    }
}))

const WashTextFi = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: '600',
    marginLeft: '22px',
    marginTop: '25px',
    color: '#305349',
    letterSpacing: '1px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '-5px',
        marginTop: '18px',
    }
}))



const InfoBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginLeft: '20px',
    marginTop: '12px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '15px',
        marginTop: '12px',
    }
}))



const ServiceText = styled(Typography)`
    margin-left: 8px;
    color: #305349;
`;

const WashFlexBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('lg')]: {

    }
}))



const WashDry = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    fontWeight: '500',
    marginLeft: '24px',
    marginTop: '18px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '5px',
    }
}))

const WashDryTh = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    fontWeight: '500',
    marginLeft: '24px',
    marginTop: '18px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '15px',
    }
}))

const WashDryFr = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    fontWeight: '500',
    marginLeft: '24px',
    marginTop: '18px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '-10px',
    }
}))



const WashDrySec = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    fontWeight: '500',
    marginLeft: '12px',
    marginTop: '18px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '8px',
    }
}))

const WashDrySi = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    fontWeight: '500',
    marginLeft: '24px',
    marginTop: '18px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '25px',
    }
}))



const AddBox = styled(Box)(({ theme }) => ({
    width: '120px',
    height: '27px',
    borderRadius: '10px',
    background: '#305349',
    marginLeft: '140px',
    marginTop: '60px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '30px',
        marginTop: '30px',
    }

}))


const AddBoxSec = styled(Box)(({ theme }) => ({
    width: '120px',
    height: '27px',
    borderRadius: '10px',
    background: '#305349',
    marginLeft: '110px',
    marginTop: '60px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '30px',
        marginTop: '30px',
    }

}))


const AddBoxTh = styled(Box)(({ theme }) => ({
    width: '120px',
    height: '27px',
    borderRadius: '10px',
    background: '#305349',
    marginLeft: '165px',
    marginTop: '60px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '30px',
        marginTop: '30px',
    }
}))



const AddBoxFo = styled(Box)(({ theme }) => ({
    width: '120px',
    height: '27px',
    borderRadius: '10px',
    background: '#305349',
    marginLeft: '127px',
    marginTop: '60px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '30px',
        marginTop: '30px',
    }
}))



const IconLenght = styled(Box)`
    display: flex;
    margin-left: 20px;
    margin-top: 1px;
`;

const AddText = styled(Typography)`
    color: white;
    margin-left: 10px;
`;

const ModuleBox = styled(Box)`
    display: flex;
    margin: 10px;
`

const SecModuleBox = styled(Box)`
    width: 200px;
    height: 150px;
    margin: 10px;
    border: 2px solid #305349;
    border-radius: 10px;
`

const ImageLogo = styled('img')({
    marginLeft: '75px',
    marginTop: '8px',
})

const Mixtext = styled(Typography)`
    font-size: 15px;
    font-weight: 600;
    color: #305349;
    text-align: center;
    margin-top: 5px;
`

const SecBox = styled(Box)`
    width: 200px;
    height: 50px;
 
    display: flex;
`

const ImageLogoSec = styled('img')({
    width: '30px',
    height: '30px',
    marginTop: '8px',

})

const MixtextSec = styled(Typography)`
    font-size: 13px;
    font-weight: 600;
    color: #305349;
    margin-left: 12px;
    margin-top: 15px;
`


const SlectedServiceBooking = () => {

    const [selectservices, setservices] = useState([])
    const [selectservicesSec, setServicesSec] = useState([])
    const [selectservicesTh, setServicesTh] = useState([])
    const [selectservicesFr, setServicesFr] = useState([])

    const handleClick = (newdata) => {
        setservices(selectservices => [...selectservices, newdata])

    }

    const handleClickSec = (newdataSec) => {
        setServicesSec(selectservicesSec => [...selectservicesSec, newdataSec])
    }

    const handleClickTh = (newdataTh) => {
        setServicesTh(selectservicesTh => [...selectservicesTh, newdataTh])
    }

    const handleClickFr = (newdataFr) => {
        setServicesFr(selectservicesFr => [...selectservicesFr, newdataFr])
    }


    return (
        <>
            <ManiLastBox>
                <MainBox>
                    <QuichText>"Booking Laundry"</QuichText>
                </MainBox>
            </ManiLastBox>


            <MainLast>
                <TextBox>
                    <TextFind>What services do you need?</TextFind>

                    <FormText>
                        <IconBox>
                            <ImageIcon src="Image/Image-10.png" alt="" />
                            <Box>
                                <WashText>Wash</WashText>
                                <InfoBox>
                                    <InfoIcon style={{ color: '#305349' }} />
                                    <ServiceText>Service details</ServiceText>
                                </InfoBox>

                                <WashFlexBox>
                                    <WashDry>WASH</WashDry>
                                    <AddIcon style={{ marginTop: '15.5px', marginLeft: '10px', color: '#305349' }} />
                                    <WashDrySec>TUMBLE-DRY</WashDrySec>

                                </WashFlexBox>
                            </Box>

                            <AddBox>
                                <IconLenght>
                                    <AddIcon style={{ color: 'white' }} />
                                    <Link data-toggle="modal" data-target="#exampleModalLong" type="submit" style={{ textDecoration: 'none' }}>

                                        {selectservices == '' ? (<AddText> Add</AddText>) : (<AddText  > Remove</AddText>)}

                                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLongTitle" style={{ color: '#305349' }}>Please select your preference for Wash</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <ModuleBox>
                                                            <SecModuleBox onClick={() => handleClick({
                                                                id: 1,
                                                                Image: 'https://app.laundryheap.com/images/icons/services/mixed_wash.svg',
                                                                Text: 'Mixed Wash',
                                                                Text2: '90°F',
                                                                SecondText: '$65.70 / 30lbs'
                                                            })}>
                                                                <ImageLogo src="https://app.laundryheap.com/images/icons/services/mixed_wash.svg" alt="" />

                                                                <Mixtext style={{ color: '#305349' }}>Mixed Wash</Mixtext>
                                                                <Mixtext style={{ color: '#305349' }}>90°F</Mixtext>
                                                                <Mixtext style={{ color: '#305349' }} s>$32.85 / 15lbs</Mixtext>



                                                            </SecModuleBox>

                                                            <SecModuleBox onClick={() => handleClick({
                                                                id: 1,
                                                                Image: 'https://app.laundryheap.com/images/icons/services/mixed_wash.svg',
                                                                Text: 'Separate Wash',
                                                                Text2: '90°F',
                                                                SecondText: '$65.70 / 30lbs'
                                                            })}>
                                                                <ImageLogo src="https://app.laundryheap.com/images/icons/services/mixed_wash.svg" alt="" />

                                                                <Mixtext style={{ color: '#305349' }}>Separate Wash</Mixtext>
                                                                <Mixtext style={{ color: '#305349' }}>90°F</Mixtext>
                                                                <Mixtext style={{ color: '#305349' }}>$65.70 / 30lbs</Mixtext>



                                                            </SecModuleBox>
                                                        </ModuleBox>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Link>


                                </IconLenght>

                            </AddBox>

                        </IconBox>
                    </FormText>

                    <FormText>
                        <IconBox>
                            <ImageIcon src="Image/Image-3.png" alt="" />
                            <Box>
                                <WashTextSec>Wash & Iron</WashTextSec>
                                <InfoBox>
                                    <InfoIcon style={{ color: '#305349' }} />
                                    <ServiceText>Service details</ServiceText>
                                </InfoBox>

                                <WashFlexBox>
                                    <WashDryTh>WASH</WashDryTh>
                                    <AddIcon style={{ marginTop: '15.5px', marginLeft: '10px', color: '#305349' }} />
                                    <WashDrySec>IRONING</WashDrySec>

                                </WashFlexBox>
                            </Box>

                            <AddBoxTh>
                                <IconLenght>
                                    <AddIcon style={{ color: 'white' }} />
                                    <Link >
                                        {selectservicesSec == '' ? (<AddText onClick={() => handleClickSec({
                                            id: 2,
                                            ImageSec: 'Image/Image-3.png',
                                            TextSec: 'Wash & Iron',
                                            Text2: '90°F',
                                            SecondText: '$65.70 / 30lbs'
                                        })}> Add</AddText>) : (<AddText  > Remove</AddText>)}
                                    </Link>
                                </IconLenght>
                            </AddBoxTh>

                        </IconBox>
                    </FormText>


                    <FormText>
                        <IconBox>
                            <ImageIcon src="Image/Image-9.png" alt="" />
                            <Box>
                                <WashTextTh>Dry Cleaning</WashTextTh>
                                <InfoBox>
                                    <InfoIcon style={{ color: '#305349' }} />
                                    <ServiceText>Service details</ServiceText>
                                </InfoBox>

                                <WashFlexBox>
                                    <WashDryFr>DRY CLEANING</WashDryFr>
                                    <AddIcon style={{ marginTop: '15.5px', marginLeft: '10px', color: '#305349' }} />
                                    <WashDrySec>IRONING</WashDrySec>

                                </WashFlexBox>
                            </Box>

                            <AddBoxSec>
                                <IconLenght>
                                    <AddIcon style={{ color: 'white' }} />
                                    <Link >
                                        {selectservicesTh == '' ? (<AddText onClick={() => handleClickTh({
                                            id: 3,
                                            ImageSec: 'Image/Image-9.png',
                                            TextTh: 'Dry Cleaning',
                                            Text2: '90°F',
                                            SecondText: '$65.70 / 30lbs'
                                        })}> Add</AddText>) : (<AddText  > Remove</AddText>)}
                                    </Link>
                                </IconLenght>
                            </AddBoxSec>

                        </IconBox>
                    </FormText>


                    <FormText>
                        <IconBox>
                            <ImageIcon src="Image/Image-1.png" alt="" />
                            <Box>
                                <WashTextFi>Duvets & Bulky Items</WashTextFi>
                                <InfoBox>
                                    <InfoIcon style={{ color: '#305349' }} />
                                    <ServiceText>Service details</ServiceText>
                                </InfoBox>

                                <WashFlexBox>
                                    <WashDrySi>CUSTOM CLEANING</WashDrySi>


                                </WashFlexBox>
                            </Box>

                            <AddBoxFo>
                                <IconLenght>
                                    <AddIcon style={{ color: 'white' }} />
                                    <Link >
                                        {selectservicesFr == '' ? (<AddText onClick={() => handleClickFr({
                                            id: 4,
                                            ImageSec: 'Image/Image-1.png',
                                            TextFr: 'Duvets & Bulky Items',
                                            Text2: '90°F',
                                            SecondText: '$65.70 / 30lbs'
                                        })}> Add</AddText>) : (<AddText  > Remove</AddText>)}
                                    </Link>

                                </IconLenght>
                            </AddBoxFo>

                        </IconBox>
                    </FormText>


                </TextBox>

                <MainThBox>
                    <Link to='/contectus' style={{ textDecoration: 'none' }} >
                        <NextBox>
                            <NextText>Next</NextText>
                        </NextBox>
                    </Link>

                    <hr />

                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>

                        <Link to='/booking' style={{ textDecoration: 'none' }}>
                            <AddressText>Address</AddressText>
                        </Link>
                    </AdressBox>
                    <hr />

                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>
                        <Link to='/bookingcollection' style={{ textDecoration: 'none' }}>
                            <AddressText>Collection time</AddressText>
                        </Link>
                    </AdressBox>




                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>
                        <Link to='/bookingcollection' style={{ textDecoration: 'none' }}>
                            <AddressText>Delivery time</AddressText>
                        </Link>
                    </AdressBox>



                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>
                        <Link to='/bookingcollection' style={{ textDecoration: 'none' }}>
                            <AddressText>Frequency</AddressText>
                        </Link>
                    </AdressBox>

                    <hr />

                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>
                        <Link to='/selectedservice' style={{ textDecoration: 'none' }}>
                            <AddressText>Selected services</AddressText>
                            {selectservices.map((data) => (
                                <SecBox>
                                    <ImageLogoSec src="https://app.laundryheap.com/images/icons/services/mixed_wash.svg" alt="" />
                                    <MixtextSec>{data.Text}</MixtextSec>
                                </SecBox>

                            ))}

                            {selectservicesSec.map((data) => (
                                <SecBox>
                                    <ImageLogoSec src="Image/Image-3.png" alt="" />
                                    <MixtextSec>{data.TextSec}</MixtextSec>
                                </SecBox>

                            ))}

                            {selectservicesTh.map((data) => (
                                <SecBox>
                                    <ImageLogoSec src="Image/Image-9.png" alt="" />
                                    <MixtextSec>{data.TextTh}</MixtextSec>
                                </SecBox>

                            ))}

                            {selectservicesFr.map((data) => (
                                <SecBox>
                                    <ImageLogoSec src="Image/Image-1.png" alt="" />
                                    <MixtextSec>{data.TextFr}</MixtextSec>
                                </SecBox>

                            ))}
                        </Link>
                    </AdressBox>

                    <hr />

                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '30px' }} />
                        </DoneIcon>
                        <AddressText>Contact</AddressText>
                    </AdressBox>

                    <hr />

                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '30px' }} />
                        </DoneIcon>
                        <AddressText>Payment</AddressText>
                    </AdressBox>
                    <hr/>
                </MainThBox>

            </MainLast>
        </>
    )
}

export default SlectedServiceBooking
