import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';


const MainBoxFild = styled(Box)(({theme})=> ({
    display: 'flex',
    marginTop: '100px',
   
    [theme.breakpoints.down('lg')]: {
        display: 'block',
        marginTop: '60px',
        overflow: 'hidden',
    }
}))



const SecondMainBox = styled(Box)(({theme})=> ({
    marginLeft: '63px',
    marginBottom: '10px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '0px',
        marginBottom: '10px',
        overflow: 'hidden',
    }

}))


const ServiceText = styled(Typography)(({theme})=> ({
    fontSize: '20px',
    fontWeight: '500',
    color: 'grey',
    marginLeft: '150px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '18px',
        marginLeft: '145px',
        fontWeight: '600',

    }

}))


const ServiceTextPerFact = styled(Typography)(({theme})=> ({
    fontSize: '42px',
    fontWeight: '600',
    color: '#305349',
    marginLeft: '150px',
    marginTop: '20px',
    lineHeight: '25px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '22px',
        textAlign: 'center',
        marginLeft: '-5px',
        marginTop: '20px',
        lineHeight: '15px', 

    }
}))



const SecondMainFild = styled(Box)(({theme})=> ({
    width: '200px',
    height: '50px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 4px 2px #d9d9d9',
    display: 'flex',
    borderRadius: '6px',
    marginLeft: '603px',
    marginTop: '50px',
    [theme.breakpoints.down('lg')]: {
        display: 'Block',
        marginLeft: '95px',
        marginTop: '40px',
    }

}))


const HomeBox = styled(Box)`
    width: 185px;
    height: 40px;
    border-radius: 6px;
    margin-top: 5px;
    margin-left: 7px;

`;



const HomeFild = styled(Typography)(({theme})=> ({
    fontSize: '18px',
    fontWeight: '600',
    color: 'black',
    textAlign: 'center',
    marginTop: '6px',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
        padding: '12px',
     
    }
}))



const ReviewMainBox = styled(Box)(({theme})=> ({
    display: 'flex',
    marginTop: '70px',
    marginLeft: '160px',
    marginBottom: '100px',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
        marginTop: '40px',
        marginLeft: '86px',
      
    }

}))




const ImagePng = styled('img')(({theme})=> ({
    width: '332px',
    height: '330px',

    marginLeft: '55px',
    [theme.breakpoints.down('lg')]: {
      width: '300px',
      height: '300px',
      marginLeft: '-41px',
      marginTop: '15px',

    }
}))


const HowBox = styled(Box)(({theme})=> ({
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
 
    }
}))
    


const HowClean = styled(Typography)(({theme})=> ({
    fontSize: '18px',
    color: 'black',
    fontWeight: '500',
    marginTop: '20px',
    marginLeft: '55px',

    [theme.breakpoints.down('lg')]: {
        fontSize: '20px',
        marginTop: '15px',
        marginLeft: '-40px',
       
    }
}))



const HowCleanSec = styled(Typography)(({theme})=> ({
    fontSize: '18px',
    color: 'black',
    fontWeight: '500',
    lineHeight: '20px',

    marginLeft: '55px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '20px',
        marginLeft: '-40px',
        lineHeight: '-10px',

    }
}))



const IconStyle = styled(Box)(({theme})=> ({
    marginLeft: '85px',
    marginTop: '20px',
    color: '#20313B',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '50px',
        marginTop: '15px',
  
    }
}))



const ByCrestText = styled(Typography)(({theme})=> ({
    marginTop: '10px',
    fontWeight: '500',
    letterSpacing : '1px',
    marginLeft: '55px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '-40px',
        marginTop: '15px',
    
    }

}))

const ImageBox = styled(Box)(({
    marginLeft: '80px',
}))

const ImageGirl = styled('img')(({theme})=> ({
    width: '40px',
    height: '40px',

    marginTop: '0px',
    borderRadius: '50%',
    [theme.breakpoints.down('lg')]: {
        padding: '20px',
        marginLeft: '5px',
        marginTop: '15px',
     
    }
}))



const TenHomePage = () => {
    return (
        <>
            <MainBoxFild>
                <SecondMainBox>
                    <ServiceText>OUR BLOG</ServiceText>
                    <ServiceTextPerFact>Interesting and</ServiceTextPerFact>
                    <ServiceTextPerFact>useful</ServiceTextPerFact>
                </SecondMainBox>

                <SecondMainFild>
                    <HomeBox>
                        <HomeFild>Explore All</HomeFild>
                    </HomeBox>
                </SecondMainFild>
            </MainBoxFild>

            <ReviewMainBox>

                    <Box>
                        <ImagePng src="Image/20.jpg" alt="" />
                        <HowBox>
                            <Box>
                                <HowClean>How to clean the carpet at</HowClean>
                                <HowCleanSec>home from stains?</HowCleanSec>
                                <ByCrestText>By Cristiana</ByCrestText>
                            </Box>
                            <Box>
                                <IconStyle>
                                    <BookmarkIcon style={{ fontSize: '35px' }} />
                                </IconStyle>

                                <ImageBox>
                                <ImageGirl src="https://1.bp.blogspot.com/-fJm248OAIQk/YE-mjgyFALI/AAAAAAAAK0A/8Yq8Tuiv7JIEsw7FnvY0rJbSuEGxtkUBgCEwYBhgLKtQDAL1Ocqwux5XwcHCIGS8hJLVaGckj6v3OeKYuSjyZDksJH352mt10fRxnJ3ZeU_ORzV_9ovyGWm1ARPZVbJBIAn9jlUIEv9Q-qBlveyZh3xOgJQxhH1enrccnIGoTqaYDl15lXSSERnocx0Pz1sH1WNQ4rG-zFI5Acu4DaVZ_PwI91r0ZHCtuTbPChLfn5ikP6QG1Edvs5ifrFntGIB5rQrX5fqY4ZBKlfiH3yH1qeqcxgS2HFqJ0XaIavtftoFCmEJ0L4EgGWuS5uiaBdmsd-D5nS4tQjLIiAb1dJM6EmPOGJKdp5UuxKmt0QwKM17-hEmXebMMMDBEm61Aq8TOhFwHlawB9mCpiAaBQnwWRBuhEgT93oE8ODQ0eDCy05IUg9R8_3HanYt1ErceFPbV3ocjeMIaG1E3DOpIipf-uX8Y1A9HZnmxEApBV5gYxiX06CPFm-BNnhfUe2HJmRc8TUWD2N2v5BybkGPZsL7cAwTobdB2K7pTiY75-OD6qxxBJyU6hExIAfkJBQeojZtanp1M3vhe0dcueOMVqPiItzzGA-o4xHqvL3FSPF5VJssWRSvXqM_gnk3vxz-a9BQH49qszrERbqdvVkDpa-8ueBrh0mvhXMPTRvoIG/s1600/Indian%2Bsimple%2Bgirl%2Bprofile%2Bpicture3.jpg" alt="" />
                                </ImageBox>

                            </Box>
                        </HowBox>
                    </Box>

                    <Box>
                        <ImagePng src="Image/21.jpg" alt="" />
                        <HowBox>
                            <Box>
                                <HowClean>Professional approach the</HowClean>
                                <HowCleanSec>cleaning process</HowCleanSec>
                                <ByCrestText>By Cristofer</ByCrestText>
                            </Box>
                            <Box>
                                <IconStyle>
                                    <BookmarkIcon style={{ fontSize: '35px' }} />
                                </IconStyle>
                                <ImageBox>
                                <ImageGirl src="https://as2.ftcdn.net/v2/jpg/02/33/57/49/1000_F_233574912_c6LHviRbGG2moVQgGcwT0tdIo7HcuW3M.jpg" alt="" />
                                </ImageBox>
                             
                            </Box>
                        </HowBox>
                    </Box>

                    <Box>
                        <ImagePng src="Image/22.jpg" alt="" />
                        <HowBox>
                            <Box>
                                <HowClean>Housewife tips for cleaning</HowClean>
                                <HowCleanSec>upholstered furniture</HowCleanSec>
                                <ByCrestText>By Denial Roy</ByCrestText>
                            </Box>
                            <Box>
                                <IconStyle>
                                    <BookmarkIcon style={{ fontSize: '35px' }} />
                                </IconStyle>
                                <ImageBox>
                                <ImageGirl src="https://media.istockphoto.com/photos/handsome-man-wearing-white-clothes-posing-in-sea-scenery-picture-id537548268?b=1&k=20&m=537548268&s=170667a&w=0&h=dcBVB6QaOdcnwJ_z9iX1MZj_2PtZ6XofWV6BZASZcx4=" alt="" />
                                </ImageBox>
                            
                            </Box>
                        </HowBox>
                    </Box>

            </ReviewMainBox>
        </>
    )
}

export default TenHomePage
