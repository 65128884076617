import React from 'react'
import { Box, Typography, styled } from '@mui/material';

const ManiLastBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/23.jpg")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '480px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: '#fff',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('lg')]: {
        marginTop: '50px',
        height: '220px',

    }
}))

const MainBox = styled(Box)(({ theme }) => ({
    padding: '220px',
    [theme.breakpoints.down('lg')]: {
        padding: '55px',
    }
}))




const QuichText = styled(Typography)(({ theme }) => ({
    fontWeight: '600',
    fontSize: '62px',
    color: '#fffff',
    lineHeight: '70px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
        fontSize: '28px',
        lineHeight: '80px',
    }
}))

const HiddenText = styled(Typography)(({theme})=> ({
    fontSize: '22px',
    color: '#fffff',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
        fontSize: '18px',
    }
}))



const MainSecBox = styled(Box)(({theme})=> ({
    width: '100%',
    height: '120px',
    background: '#305349',
    [theme.breakpoints.down('lg')]: {
        height: '300px',
    }
}))
 


const BoxFlexBox = styled(Box)(({theme})=> ({
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
    }
}))



const ImageIcon = styled('img')(({theme})=> ({
    width: '48px',
    height: '48px',
    marginLeft: '380px',
    marginTop: '38px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '70px',
    }
}))



const FreeTh = styled(Typography)(({theme})=> ({
    color: 'white',
    fontWeight: '600',
    marginLeft: '460px',
    marginTop: '-34px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '150px',
    }

}))


const ImageIconSec = styled('img')(({theme})=> ({
    width: '48px',
    height: '48px',
    marginLeft: '70px',
    marginTop: '38px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '48px',
    }
}))


const FreeThSec = styled(Typography)(({theme})=> ({
    color: 'white',
    fontWeight: '600',
    marginLeft: '140px',
    marginTop: '-34px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '150px',
        marginTop: '-35px',
    }
}))



const ImageIconTh = styled('img')(({theme})=> ({
    width: '48px',
    height: '48px',
    marginLeft: '70px',
    marginTop: '38px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '48px',
    }
}))



const FreeThSecTh = styled(Typography)(({theme})=> ({
    color: 'white',
    fontWeight: '600',
    marginLeft: '140px',
    marginTop: '-34px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '150px',
        marginTop: '-34px',
    }
}))



const SecondServicesBox = styled(Box)(({theme})=> ({
    display: 'flex',
    marginLeft: '205px',
    marginTop: '50px',
    marginBottom: '50px',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
        marginLeft: '22px',
        marginTop: '40px',
    }

}))

const StyleBox = styled(Box)(({theme})=> ({
    width: '355px',
    height: '300px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 4px 2px #d9d9d9',
    margin: '10px',
    [theme.breakpoints.down('lg')]: {
        width: '325px',
        height: '300px',
       
    }
}))



const ImageWashing = styled('img')(({theme})=> ({
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    marginLeft: '140px',
    marginTop: '25px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '120px',
    }
}))


const PulmbingText = styled(Typography)`
    font-size: 16px;
    font-weight: 600;
    color: #305349;
    margin-top: 15px;
    text-align: center;
    letter-spacing : 1px;
    
`;



const YouMainBox = styled(Box)(({theme})=> ({
    width: '100%',
    borderRadius: '0px',
    height: '120px',
    background: '#305349',
    marginLeft: '0px',
    marginTop: '30px',
    [theme.breakpoints.down('lg')]: {
        height: '95px',
    }

}))


const MainTextYou = styled(Typography)`
    font-size: 14px;
    font-weight: 500;
    color: white;
    margin-top: 20px;
    padding: 40px;
`;

const MainTextYouSec = styled(Typography)`
    font-size: 14px;
    font-weight: 500;
    color: white;
    margin-top: -30px;
    margin-left: 40px;
`;

const TextYou = styled(Typography)`
    font-size: 13px;
    font-weight: 500;
    color: #305349;
    text-align: center;
    margin-top: 10px;
    padding: -20px;
`;




const PriceServices = () => {
    return (
        <>
            <ManiLastBox>
                <MainBox>
                    <QuichText>"Simple pricing"</QuichText>
                    <HiddenText>No hidden fees.</HiddenText>
                </MainBox>
            </ManiLastBox>

            <MainSecBox>
                <BoxFlexBox>
                    <Box>
                        <ImageIcon src="https://prod-cdn.laundryheap.com/images/static/price_info/web/free_delivery.svg" alt="" />
                        <FreeTh>Free 24h delivery</FreeTh>
                    </Box>

                    <Box>
                        <ImageIconSec src="https://prod-cdn.laundryheap.com/images/static/price_info/web/minimum_order.svg" alt="" />
                        <FreeThSec>£20 minimum order</FreeThSec>
                    </Box>

                    <Box>
                        <ImageIconTh src="https://prod-cdn.laundryheap.com/images/static/price_info/web/service_fee.svg" alt="" />
                        <FreeThSecTh>Service fee from £1.99</FreeThSecTh>
                    </Box>

                </BoxFlexBox>
            </MainSecBox>

            <SecondServicesBox>
                <StyleBox>
                    <ImageWashing src="Image/Image-2.png" alt="" />
                    <PulmbingText>Wash</PulmbingText>
                    <TextYou>For everyday laundry, bedsheets and towels.</TextYou>
                    <YouMainBox>
                        <MainTextYou>PRICE PER WEIGHT</MainTextYou>
                        <MainTextYouSec>from $20.85 /15 lbs</MainTextYouSec>
                    </YouMainBox>

                </StyleBox>

                <StyleBox>
                    <ImageWashing src="Image/Image-3.png" alt="" />
                    <PulmbingText>Wash & Iron</PulmbingText>
                    <TextYou>For everyday laundry that requires ironing.</TextYou>
                    <YouMainBox>
                        <MainTextYou>PRICE PER ITEM</MainTextYou>
                        <MainTextYouSec>from $18.49</MainTextYouSec>
                       
                    </YouMainBox>


                </StyleBox>

                <StyleBox>
                    <ImageWashing src="Image/Image-5.png" alt="" />
                    <PulmbingText>Dry Cleaning</PulmbingText>
                    <TextYou>For delicate items and fabrics.</TextYou>
                    <YouMainBox>
                        <MainTextYou>PRICE PER ITEM</MainTextYou>
                        <MainTextYouSec>from $8.49</MainTextYouSec>
                        
                    </YouMainBox>
                </StyleBox>

                
            </SecondServicesBox>


            <SecondServicesBox>
                <StyleBox>
                    <ImageWashing src="Image/Image-1.png" alt="" />
                    <PulmbingText>Haute Couture Dry Cleaning</PulmbingText>
                    <TextYou>Hohenstein certified dry cleaning process to restore</TextYou>
                    <YouMainBox>
                        <MainTextYou>PRICE PER WEIGHT</MainTextYou>
                        <MainTextYouSec>from $32.85 /15 lbs</MainTextYouSec>
                    </YouMainBox>

                </StyleBox>

                <StyleBox>
                    <ImageWashing src="Image/Image-4.png" alt="" />
                    <PulmbingText>Wash and Fold</PulmbingText>
                    <TextYou>For everyday laundry that requires ironing.</TextYou>
                    <YouMainBox>
                        <MainTextYou>PRICE PER ITEM</MainTextYou>
                        <MainTextYouSec>from $9.49</MainTextYouSec>
                       
                    </YouMainBox>


                </StyleBox>

                <StyleBox>
                    <ImageWashing src="Image/Image-10.png" alt="" />
                    <PulmbingText>Dyeing</PulmbingText>
                    <TextYou>For delicate items and fabrics.</TextYou>
                    <YouMainBox>
                        <MainTextYou>PRICE PER ITEM</MainTextYou>
                        <MainTextYouSec>from $6.49</MainTextYouSec>
                        
                    </YouMainBox>
                </StyleBox>

                
            </SecondServicesBox>
        </>
    )
}

export default PriceServices
