import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './Header/Footer';
import Home from './Header/Home';
import Navbar from './Header/Navbar';
import AboutPage from './InsidePage/AboutPage';
import BookingService from './InsidePage/BookingService';
import HelpCenter from './InsidePage/HelpCenter';
import PriceServices from './InsidePage/PriceServices';
import Services from './InsidePage/Services';
import BookingCollection from './InsidePage/BookingCollection';
import SlectedServiceBooking from './InsidePage/SlectedServiceBooking';
import ContactBooking from './InsidePage/ContactBooking';
import ContactbookingSec from './InsidePage/ContactbookingSec';
import PaymentGatewat from './InsidePage/PaymentGatewat';


function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/service' element={<Services/>} />
          <Route path='/aboutpage' element={<AboutPage/>} />
          <Route path='/price' element={<PriceServices/>} />
          <Route path='/helpcenter' element={<HelpCenter/>}/>
          <Route path='/booking' element={<BookingService/>} />
          <Route path='/bookingcollection' element={<BookingCollection/>} />
          <Route path='/selectedservice' element={<SlectedServiceBooking/>} />
          <Route path='/contectus' element={<ContactBooking/>}/>
          <Route path='/company' element={<ContactbookingSec/>} />
          <Route path='/payment' element={<PaymentGatewat/>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
