import { Box, styled, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import CallIcon from '@mui/icons-material/Call';


const ImageIcon = styled('img')({
    width: '90px',
    height: '50px',
})


const FormBox = styled(Box)(({ theme }) => ({
    marginLeft: '234px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '95px',
    }
}))


const ThirdBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginLeft: '16px',
    marginTop: '3px',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
        marginLeft: '0px',
        marginTop: '0px',
    }
}))



const CallstyleBox = styled(Box)(({ theme }) => ({
    width: '150px',
    height: '40px',
    background: '#305349',
    borderRadius: '5px',
    border: '1px solid green',
    color: 'white',
    [theme.breakpoints.down('lg')]: {

    }
}))




const TypoText = styled(Box)(({ theme }) => ({
    display: 'flex',
    fontSize: '18px',
    padding: '10px',
    marginLeft: '18px',
    [theme.breakpoints.down('lg')]: {

    }
}))






const CallUsText = styled(Typography)(({ theme }) => ({
    marginLeft: '8px',
    marginTop: '-3px',
    [theme.breakpoints.down('lg')]: {

    }
}))



const Navbar = () => {
    return (
        <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
            <Link to='/'>
                <ImageIcon src="https://www.pngkey.com/png/detail/389-3897131_green-cleaning-logo-green-cleaning.png" alt="" />
            </Link>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav" style={{ marginLeft: '100px' }}>

                    <li className="nav-item active">
                        <Link className="nav-link" to="/">How it work</Link>
                    </li>

                    <li className="nav-item active">
                        <Link className="nav-link" to="/service">Our Services</Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to='/price'>Prices & Services</Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to='#'>About Our Company</Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to='/helpcenter'>Help Center</Link>
                    </li>
                    {/* <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            About us
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a className="dropdown-item" href="#">Action</a>
                            <a className="dropdown-item" href="#">Another action</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">Something else here</a>
                        </div>
                    </li> */}

                </ul>
                <FormBox>
                    <form className="form-inline my-2 my-lg-0">
                        <ThirdBox>
                            <CallstyleBox>
                                <Link to='/booking' style={{color: 'white', textDecoration: 'none' }}>
                                    <TypoText>
                                        <CallIcon style={{ marginLeft: '-10', marginTop: '-3px' }} />
                                        <CallUsText>Book Now</CallUsText>
                                    </TypoText>
                                </Link>
                            </CallstyleBox>

                        </ThirdBox>

                        <button className="btn btn-outline-success my-1 my-sm-0" type="submit" style={{ marginLeft: '52px' }} >Login</button>
                    </form>
                </FormBox>

            </div>
        </nav>
    )
}

export default Navbar
