import React from 'react'
import { Box, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState } from 'react';


const ManiLastBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/23.jpg")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '480px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: '#fff',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('lg')]: {
        marginTop: '50px',
        height: '180px',

    }
}))

const MainBox = styled(Box)(({ theme }) => ({
    padding: '220px',
    [theme.breakpoints.down('lg')]: {
        padding: '55px',
    }
}))




const QuichText = styled(Typography)(({ theme }) => ({
    fontWeight: '600',
    fontSize: '62px',
    color: '#fffff',
    lineHeight: '70px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
        fontSize: '28px',
        lineHeight: '80px',
    }
}))


const MainLast = styled(Box)(({theme})=> ({
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
    }
}))



const TextBox = styled(Box)(({theme})=> ({
    marginLeft: '220px',
    marginTop: '86px',
    marginBottom: '50px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '0px',
    }
}))



const TextFind = styled(Typography)(({theme})=> ({
    fontWeight: '600',     
    fontSize: '42px',
    color: '#305349',
    [theme.breakpoints.down('lg')]: {
        fontSize: '22px',
        textAlign: 'center',
    }
}))




const FormText = styled(Box)(({theme})=> ({
    marginTop: '15px',
    [theme.breakpoints.down('lg')]: {
        padding: '20px',
    }
}))




const MainThBox = styled(Box)(({theme})=> ({
    width: '350px',
    height: '710px',
    borderTop: '0.5px solid #305349',
    borderLeft: '0.5px solid #305349',
    borderRight: '0.5px solid #305349',
   
    overflow: 'hidden',
    borderRadius: '4px',
    marginTop: '55px',
    marginBottom: '55px',
    marginLeft: '327px',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '20px',
      marginTop: '25px',
      marginBottom: '75px',
    }
  
  }))
   
  
const NextBox = styled(Box)`
    width: 325px;
    height: 50px;
    background: #305349;
    border-radius: 5px;
    border: 1px solid green;
    color: white;
    margin-left: 12px;
    margin-top: 12px;
`;


  const NextText = styled(Typography)`
      text-align: center;
      margin-top: 10px;
      font-size: 18px;
      letter-spacing: 2px;
  `
  
  const AdressBox = styled(Box)`
      display: flex;
      margin-left: 40px;
      margin-top: 5px;
  `
  const DoneIcon = styled(Box)`
      margin-left: 0px;
      margin-top: 10px;
  `
  
  const AddressText = styled(Typography)`
      margin-left: 20px;
      margin-top: 12px;
      color: #305349;
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
      letter-spacing: 1px;
  `
  

const TimeSetMain = styled(Typography)(({theme})=> ({
    color: 'grey',
    marginBottom: '15px',
    [theme.breakpoints.down('lg')]: {
        textAlign: 'center'
    }
}))



const TimeBoxMain = styled(Typography)`
    color: grey;
    margin-bottom: 5px;
    margin-top: -5px;
`

const TimeSetText = styled(Typography)`
    color: #305349;
    font-size: 14px;
    font-weight: 500;
    margin-left: 20px;
    margin-top: 5px;
`




const BookingCollection = () => {

    const [time, setTime] = useState({timeset: ''})
    const [date, setDate] = useState({dateset: ''})

    const [timesec, setTimeSec] = useState({timesetsec: ''})
    const [datesec, setDateSec] = useState({datesetsec: ''})

    const [drowp, setDrowp] = useState({dropset: ''})
    const [drowpsec, setDrowpSec] = useState({dropsetsec: ''})


    return (
        <>
            <ManiLastBox>
                <MainBox>
                    <QuichText>"Booking Laundry"</QuichText>
                </MainBox>
            </ManiLastBox>


            <MainLast>
                <TextBox>
                    <TextFind>Collection & delivery</TextFind>

                    <FormText>
                        <TimeSetMain>Collection time</TimeSetMain>

                        <div class="row">
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="date">Select day</label>
                                    <input type="date" id="datename"  class="form-control form-control-md" value={time.timeset} onChange={e => setTime({timeset: e.target.value})} />

                                </div>

                            </div>
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="time">Select time</label>
                                    <input type="time" id="time" class="form-control form-control-md" value={date.dateset} onChange={e => setDate({dateset: e.target.value})} />

                                </div>

                            </div>
                        </div>

                        <TimeBoxMain>Driver instructions</TimeBoxMain>

                        <select class="form-group form-select form-control" aria-label="Default select example" value={drowp.dropset} onChange={e => setDrowp({dropset: e.target.value})}>
                            <option >Driver collects from you</option>
                            <option >Driver collects from outside</option>
                            <option >Driver collects from reception/porter</option>
                        </select>



                    </FormText>

                    <FormText>
                        <TimeSetMain>Delivery time</TimeSetMain>
                       <div class="row">
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="date">Select day</label>
                                    <input type="date" id="datename" class="form-control form-control-md" value={timesec.timesetsec} onChange={e => setTimeSec({timesetsec: e.target.value})} />

                                </div>

                            </div>
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="time">Select time</label>
                                    <input type="time" id="time" class="form-control form-control-md" value={datesec.datesetsec} onChange={e => setDateSec({datesetsec: e.target.value})} />

                                </div>

                            </div>
                        </div>

                        <TimeBoxMain>Driver instructions</TimeBoxMain>

                        <select class="form-group form-select form-control" aria-label="Default select example" value={drowpsec.dropsetsec} onChange={e => setDrowpSec({dropsetsec: e.target.value})}>
                            <option>Driver drops, rings & waits</option>
                            <option >Driver drops, rings and goes</option>
                            <option>Driver leaves bags at reception/porter</option>
                        </select>



                    </FormText>


                </TextBox>

                <MainThBox>
                    <Link to='/selectedservice' style={{ textDecoration: 'none' }} >
                        <NextBox>
                            <NextText>Next</NextText>
                        </NextBox>
                    </Link>

                    <hr/>

                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>
                        <Link to='/booking' style={{ textDecoration: 'none' }}>
                            <AddressText>Address</AddressText>
                        </Link>
                    </AdressBox>

                    <hr/>

                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>
                        <Link to='/bookingcollection' style={{ textDecoration: 'none' }}>
                            <AddressText>Collection time</AddressText>
                            <TimeSetText>{time.timeset}</TimeSetText>
                            <TimeSetText>{date.dateset}</TimeSetText>
                            <TimeSetText>{drowp.dropset}</TimeSetText>
                        </Link>
                    </AdressBox>



                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>
                        <Link to='/bookingcollection' style={{ textDecoration: 'none' }}>
                            <AddressText>Delivery time</AddressText>
                            <TimeSetText>{timesec.timesetsec}</TimeSetText>
                            <TimeSetText>{datesec.datesetsec}</TimeSetText>
                            <TimeSetText>{drowpsec.dropsetsec}</TimeSetText>
                      
                           
                        </Link>
                    </AdressBox>


                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
                        </DoneIcon>
                        <Link to='/bookingcollection' style={{ textDecoration: 'none' }}>
                            <AddressText>Frequency</AddressText>
             
                        </Link>
                    </AdressBox>

                    <hr/>

                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '30px' }} />
                        </DoneIcon>
                        <AddressText>Selected services</AddressText>
                    </AdressBox>

                    <hr/>
                    
                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '30px' }} />
                        </DoneIcon>
                        <AddressText>Contact</AddressText>
                    </AdressBox>

                    <hr/>

                    <AdressBox>
                        <DoneIcon>
                            <CheckCircleIcon style={{ fontSize: '30px' }} />
                        </DoneIcon>
                        <AddressText>Payment</AddressText>
                    </AdressBox>
                    <hr/>
                </MainThBox>
              
            </MainLast>
        </>
    )
}

export default BookingCollection
