import React from 'react'
import { Box, styled, Typography } from '@mui/material'


const MainBoxFild = styled(Box)(({theme})=> ({
    display: 'flex',
    marginTop: '120px',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
        marginTop: '10px',
    }
}))



const SecondMainBox = styled(Box)(({theme})=> ({
    marginLeft: '63px',
    marginBottom: '10px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '0px',
        marginBottom: '10px',
    }
}))



const ServiceText = styled(Typography)(({theme})=> ({
    fontSize: '18px',
    fontWeight: '500',
    color: 'grey',
    marginLeft: '150px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '18px',
        marginLeft: '120px',
        fontWeight: '600',
  
    }
}))



const ServiceTextPerFact = styled(Typography)(({theme})=> ({
        fontSize: '42px',
        fontWeight: '600',
        color: '#305349',
        marginLeft: '150px',
        marginTop: '20px',
        lineHeight: '35px',
        [theme.breakpoints.down('lg')]: {
            fontSize: '22px',
            textAlign: 'center',
            marginLeft: '2px',
            marginTop: '20px',
            lineHeight: '15px', 
        }
}))



const SecondMainFild = styled(Box)(({theme})=> ({
        width: '200px',
        height: '50px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 4px 2px #d9d9d9',
        display: 'flex',
        borderRadius: '6px',
        marginLeft: '568px',
        marginTop: '50px',
        [theme.breakpoints.down('lg')]: {
            display: 'Block',
            marginLeft: '100px',
            marginTop: '40px'
        }
}))



const HomeBox = styled(Box)(({theme})=> ({
        width: '185px',
        height: '40px',
        borderRadius: '6px',
        marginTop: '5px',
        marginLeft: '7px',
        [theme.breakpoints.down('lg')]: {
            
        }
}))






const HomeFild = styled(Typography)(({theme})=> ({
    fontSize: '18px',
    fontWeight: '600',
    color: 'black',
    textAlign: 'center',
    marginTop: '6px',
    [theme.breakpoints.down('lg')]: {
        padding: '12px',
    }
}))




const SideBox = styled(Box)(({ theme }) => ({
    width: '97%',
    marginTop: '15px',
    margin: '20px',
    [theme.breakpoints.down('lg')]: {
        width: '90%',
        
        marginLeft: '30px',
        marginTop: '50px'
    }
}))




const ReviewMainBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginLeft: '130px',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
        marginLeft: '33px',
      
    }
}))



const WeidthBox = styled(Box)(({ theme }) => ({
    width: '260px',
    height: '270px',
    background: 'white',
    margin: '30px',

    [theme.breakpoints.down('lg')]: {
        width: '250px',
        height: '250px',
        background: 'white',
        margin: '9px',
        marginTop: '-50px',
    }
}))



const GoogleFontStyleIcon = styled(Box)(({ theme }) => ({
    marginLeft: '73px',
    marginTop: '15px',

    [theme.breakpoints.down('lg')]: {
        marginTop: '50px'
    }
}))

const ImagePng = styled('img')({
    width: '90px',
    height: '90px',
})



const ReviewText = styled(Box)(({theme})=> ({
    textAlign: 'center',
    marginLeft: '-20px',
    marginTop: '15px',
    color: '#305349',
    fontWeight: '500',
    fontSize: '18px',
    letterSpacing : '1px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '18px',
        marginLeft: '5px',
        letterSpacing : '0px',
        textAlign: 'center',
    }
}))



const WasingText = styled(Box)`
    text-align: center;
   margin-left: 0px;
   margin-top: 3px;
   color: grey;
   font-weight: 500;
   font-size: 12px;
   letter-spacing : 0px;
`;



const SixHomePage = () => {
    return (
        <>
            <MainBoxFild>
                <SecondMainBox>
                    <ServiceText>OUR EQUIPMENT</ServiceText>
                    <ServiceTextPerFact>Our professional</ServiceTextPerFact>
                    <ServiceTextPerFact>equipment</ServiceTextPerFact>
                </SecondMainBox>

                <SecondMainFild>
                    <HomeBox>
                        <HomeFild>Explore All</HomeFild>
                    </HomeBox>
                </SecondMainFild>
            </MainBoxFild>

            <SideBox>

                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">

                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <Box>
                                <ReviewMainBox>
                                    <WeidthBox>
                                        <GoogleFontStyleIcon>
                                            <ImagePng src="Image/Image-1.png" alt="" />
                                        </GoogleFontStyleIcon>
                                        <ReviewText>WOOLENS DRY CLEANING</ReviewText>
                                        <WasingText>With our technology, your woolen clothes will retain their shape, size, Even if you have a washer and fluffiness.</WasingText>
                                    </WeidthBox>
                                    
                                    <WeidthBox>
                                        <GoogleFontStyleIcon>
                                            <ImagePng src="Image/Image-2.png" alt="" />
                                        </GoogleFontStyleIcon>
                                        <ReviewText>HAUTE COUTURE DRY CLEANING</ReviewText>
                                        <WasingText>Hohenstein certified dry cleaning process to restore the lustre of your silk sarees and designer Even if you have a washer wears.</WasingText>
                                    </WeidthBox>

                                    <WeidthBox>
                                        <GoogleFontStyleIcon>
                                            <ImagePng src="Image/Image-3.png" alt="" />
                                        </GoogleFontStyleIcon>
                                        <ReviewText>Wash and Iron</ReviewText>
                                        <WasingText> Garments are processed using 100% bio-degradable detergents. Ideal for daily, gym & casual wear. Rs.99/KG for Wash & Iron.</WasingText>
                                    </WeidthBox>
                                 
                                    <WeidthBox>
                                        <GoogleFontStyleIcon>
                                            <ImagePng src="Image/Image-4.png" alt="" />
                                        </GoogleFontStyleIcon>
                                        <ReviewText>Benefits Laundry Service</ReviewText>
                                        <WasingText>Even if you have a washer and dryer at home, they might not be giving your clothes and sheets the deep clean you're looking for.</WasingText>
                                    </WeidthBox>
                                    
                                </ReviewMainBox>
                            </Box>
                        </div>
                        <div className="carousel-item">

                        <Box>
                                <ReviewMainBox>
                                    <WeidthBox>
                                        <GoogleFontStyleIcon>
                                            <ImagePng src="Image/Image-5.png" alt="" />
                                        </GoogleFontStyleIcon>
                                        <ReviewText>white shirts or lighter colors.</ReviewText>
                                        <WasingText>To minimize shrinkage, we recommend letting your shirt air dry on  Silk carpets and Turkish carpets. a sturdy hanger.still damp.</WasingText>
                                    </WeidthBox>
                                    
                                    <WeidthBox>
                                        <GoogleFontStyleIcon>
                                            <ImagePng src="Image/Image-6.png" alt="" />
                                        </GoogleFontStyleIcon>
                                        <ReviewText>WOOLENS DRY CLEANING</ReviewText>
                                        <WasingText>With our Woolmark approved Lagoon technology, your woolen clothes will retain their shape, size and fluffiness.</WasingText>
                                    </WeidthBox>

                                    <WeidthBox>
                                        <GoogleFontStyleIcon>
                                            <ImagePng src="Image/Image-7.png" alt="" />
                                        </GoogleFontStyleIcon>
                                        <ReviewText>CURTAIN CLEANING</ReviewText>
                                        <WasingText>One stop solution for dry cleaning your silk, cotton, velvet or chenille curtains. as a complimentary service. </WasingText>
                                    </WeidthBox>

                                    <WeidthBox>
                                        <GoogleFontStyleIcon>
                                            <ImagePng src="Image/Image-8.png" alt="" />
                                        </GoogleFontStyleIcon>
                                        <ReviewText>CARPET CLEANING</ReviewText>
                                        <WasingText>An intense, deep-cleaning of carpets with 100% eco-friendly detergents and technologically advanced machines. Experts in Persian rugs. </WasingText>
                                    </WeidthBox>
                                    
                                </ReviewMainBox>
                            </Box>
                        </div>

                        <div className="carousel-item">
                        <Box>
                                <ReviewMainBox>
                                    <WeidthBox>
                                        <GoogleFontStyleIcon>
                                            <ImagePng src="Image/Image-9.png" alt="" />
                                        </GoogleFontStyleIcon>
                                        <ReviewText>Dry Cleaning</ReviewText>
                                        <WasingText>Dry cleaning is a process where garments are cleaned using a chemical solvent. to process your Delicate garments!</WasingText>
                                    </WeidthBox>
                                    
                                    <WeidthBox>
                                        <GoogleFontStyleIcon>
                                            <ImagePng src="Image/Image-10.png" alt="" />
                                        </GoogleFontStyleIcon>
                                        <ReviewText>5-Star Dry Cleaning</ReviewText>
                                        <WasingText>LaundroKart partnered with luxury 5-Star hotels who have best Laundry facilities and Experts who are trained to handle sensitive, expensive fabric.</WasingText>
                                    </WeidthBox>

                                    <WeidthBox>
                                        <GoogleFontStyleIcon>
                                            <ImagePng src="Image/Image-11.png" alt="" />
                                        </GoogleFontStyleIcon>
                                        <ReviewText>Wash and Fold</ReviewText>
                                        <WasingText>Garments are processed using 100% bio-degradable detergents. Ideal for daily, gym & casual wear. Rs.75/KG for Wash & Fold.</WasingText>
                                    </WeidthBox>

                                    <WeidthBox>
                                        <GoogleFontStyleIcon>
                                            <ImagePng src="Image/Image-12.png" alt="" />
                                        </GoogleFontStyleIcon>
                                        <ReviewText>Dyeing</ReviewText>
                                        <WasingText>Is the colour of your favourite attire fading away? Or are you bored of wearing  try our Dyeing service to change the colour of it.</WasingText>
                                    </WeidthBox>
                                    
                                </ReviewMainBox>
                            </Box>
                        </div>
                    </div>
                    <a className="carousel-control-prev " href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span className="carousel-control" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span className="carousel-control" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </SideBox>
        </>
    )
}

export default SixHomePage
