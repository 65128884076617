import { Box, Typography, styled } from '@mui/material';
import React from 'react';
import CallIcon from '@mui/icons-material/Call';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

const FirstMain = styled(Box)(({theme})=> ({
    display: 'flex',
    margin: 'auto',
    marginTop: '100px',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
    }
}))




const MainHeaderText = styled(Box)(({theme})=> ({
    marginLeft: '210px',
    marginTop: '100px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '0',
        marginTop: '0',
    }
}))



const FirstText = styled(Typography)(({theme})=> ({
    fontWeight: '600',
    
    fontSize: '62px',
    color: '#305349',
    lineHeight: '70px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '25px',
        lineHeight: '35px',
        marginLeft: '5px',
        textAlign: 'center',
    }
}))



const SecondBox = styled(Box)(({theme})=> ({
    marginTop: '20px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '20px',
        marginLeft: '78px',
        textAlign: 'center',
        width: '60%',
    }

}))



const WhileText = styled(Typography)(({theme})=> ({
    color: 'grey',
    lineHeight: '20px',
    [theme.breakpoints.down('lg')]: {
        
    }
}))



const ThirdBox = styled(Box)(({theme})=> ({
    display: 'flex',
    marginTop: '50px',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
        marginLeft: '96px',
        marginTop: '30px',
    }
}))



const CallstyleBox = styled(Box)`
    width: 200px;
    height: 50px;
    background: #305349;
    border: 1px solid green;
    color: white;
    
`;

const TypoText = styled(Box)(({theme})=> ({
    display: 'flex',
    fontSize: '18px',
    padding: '10px',
    marginLeft: '18px',
    [theme.breakpoints.down('lg')]: {
       
    }
}))

const TypoTextSec = styled(Box)(({theme})=> ({
    display: 'flex',
    fontSize: '18px',
    padding: '10px',
    marginLeft: '18px',
    [theme.breakpoints.down('lg')]: {
       marginTop: '25px',
       marginLeft: '4px',
    }
}))

    


const CallUsText = styled(Typography)(({theme})=> ({
    marginLeft: '10px',
    marginTop: '1px',
    [theme.breakpoints.down('lg')]: {
       
    }
}))





const ImageHeader = styled('img')(({theme})=> ({
    widht: '200px',
    height: '550px',
    marginTop: '50px',
    marginLeft: '50px',
    [theme.breakpoints.down('lg')]: {
        widht: '300px',
        height: '300px',
        marginLeft: '54px',
        marginTop: '10px',
    }
}))





const FirstHomePage = () => {
    return (
        <>
            <FirstMain>
                <MainHeaderText>
                    <FirstText>Professional</FirstText>
                    <FirstText>Cleaning Services</FirstText>
                    <FirstText>in Dallas</FirstText>
                    <SecondBox>
                        <WhileText>While you are doing something important, we will</WhileText>
                        <WhileText>put things in order in the apartment, private</WhileText>
                        <WhileText>house or office.</WhileText>
                    </SecondBox>

                    <ThirdBox>
                        <CallstyleBox>

                            <TypoText>
                                <CallIcon style={{ marginTop: '1px' }} />
                                <CallUsText>Call Us Today</CallUsText>
                            </TypoText>
                        </CallstyleBox>
                        <Box>
                            <TypoTextSec>
                                <PlayCircleFilledIcon style={{fontSize: '55px', marginTop: '-15px' }} />
                                <CallUsText>Watch Video</CallUsText>
                            </TypoTextSec>
                        </Box>
                    </ThirdBox>
                

                </MainHeaderText>
                    <ImageHeader src="Image/2.png" alt="" />
            </FirstMain>
        </>
    )
}

export default FirstHomePage
