import React from 'react'
import {Box, styled } from '@mui/material'

const FirstMainBox = styled(Box)(({theme})=> ({
    width: '100%',
    height: '130px',
    background: '#305349',
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
        width: '100%',  
        display: 'block',
        background: '#305349',
        overflow: 'hidden',
        height: '540px',
    }
}))



const ImageIcon  = styled('img')(({theme})=> ({
    width: '320px',
    height: '150px',
    margin: '0px',
    marginLeft: '120px',
    marginTop: '-10px',
    [theme.breakpoints.down('lg')]: {
        width: '410px',
        height: '200px',
        marginLeft: '0px',
     
        marginTop: '-30px',
    }
}))
 


const ImageIconSec  = styled('img')(({theme})=> ({
    width: '270px',
    height: '150px',
    margin: '0px',
    marginLeft: '-40px',
    marginTop: '-10px',
    [theme.breakpoints.down('lg')]: {
        width: '410px',
        height: '200px',
        marginLeft: '0px',
        marginTop: '-100px',
        
    }
}))

    


const ImageIconThird  = styled('img')(({theme})=> ({
    width: '254px',
    height: '150px',
    margin: '0px',
    marginLeft: '-10px',
    marginTop: '-10px',
    [theme.breakpoints.down('lg')]: {
        width: '410px',
        height: '200px',
        marginLeft: '0px',
        marginTop: '-100px',
    }
}))

    


const SecondHomePage = () => {
  return (
    <>
        <FirstMainBox>
            <ImageIcon src="Image/7.png" alt="" />
            <ImageIconSec src="Image/8.png" alt="" />
            <ImageIconThird src="Image/9.png" alt="" />
            <ImageIconThird src="Image/10.png" alt="" />

            <ImageIconThird src="Image/12.png" alt="" />
        </FirstMainBox>
    </>
  )
}

export default SecondHomePage
