import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom'
import NearMeIcon from '@mui/icons-material/NearMe';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MapGoogle from './MapGoogle';




const ManiLastBox = styled(Box)(({ theme }) => ({
  backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/23.jpg")',
  backgroundRepeat: 'no-repeat, repeat',
  height: '480px',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  color: '#fff',
  backgroundAttachment: 'fixed',
  [theme.breakpoints.down('lg')]: {
    marginTop: '50px',
    height: '180px',

  }
}))

const MainBox = styled(Box)(({ theme }) => ({
  padding: '220px',
  [theme.breakpoints.down('lg')]: {
    padding: '55px',
  }
}))




const QuichText = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontSize: '62px',
  color: '#fffff',
  lineHeight: '70px',
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    fontSize: '28px',
    lineHeight: '80px',
  }
}))


const MainLast = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('lg')]: {
    display: 'block',
  }
}))



const TextBox = styled(Box)(({ theme }) => ({
  marginLeft: '220px',
  marginTop: '100px',
  marginBottom: '50px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '20px',
    marginTop: '50px',
    marginBottom: '50px',
  }
}))



const TextFind = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontSize: '42px',
  color: '#305349',
  [theme.breakpoints.down('lg')]: {
    fontSize: '22px',
    textAlign: 'center',
  }

}))



const FormText = styled(Box)(({ theme }) => ({
  marginTop: '35px',
  [theme.breakpoints.down('lg')]: {
    padding: '20px',
    marginLeft: '-20px'
  }
}))


const ThirdBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: '0px',
  marginTop: '35px',
  [theme.breakpoints.down('lg')]: {
    display: 'block',
    marginLeft: '0px',
    marginTop: '0px',
  }
}))



const CallstyleBox = styled(Box)(({ theme }) => ({
  width: '150px',
  height: '40px',
  background: '#305349',
  borderRadius: '5px',
  border: '1px solid green',
  color: 'white',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '95px',
    marginTop: '40px'
  }
}))




const TypoText = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: '18px',
  padding: '10px',
  marginLeft: '18px',
  [theme.breakpoints.down('lg')]: {

  }
}))






const CallUsText = styled(Typography)(({ theme }) => ({
  marginLeft: '4px',
  marginTop: '-3px',
  [theme.breakpoints.down('lg')]: {

  }
}))


const MainThBox = styled(Box)(({ theme }) => ({
  width: '350px',
  height: '560px',
  border: '1px solid #305349',
  overflow: 'hidden',
  borderRadius: '4px',
  marginTop: '55px',
  marginBottom: '55px',
  marginLeft: '382px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '20px',
  }

}))


const NextBox = styled(Box)`
    width: 325px;
    height: 50px;
    background: #305349;
    border-radius: 5px;
    border: 1px solid green;
    color: white;
    margin-left: 12px;
    margin-top: 12px;
`;

const NextText = styled(Typography)`
    text-align: center;
    margin-top: 10px;
    font-size: 18px;
    letter-spacing: 2px;
`

const AdressBox = styled(Box)`
    display: flex;
    margin-left: 40px;
    margin-top: 5px;
`
const DoneIcon = styled(Box)`
    margin-left: 0px;
    margin-top: 10px;
`

const AddressText = styled(Typography)`
    margin-left: 20px;
    margin-top: 12px;
    color: #305349;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 1px;
`








const BookingService = () => {



  return (
    <>
      <ManiLastBox>
        <MainBox>
          <QuichText>"Booking Laundry"</QuichText>
        </MainBox>
      </ManiLastBox>


      <MainLast>
        <TextBox>
          <TextFind>Find your address</TextFind>

          <FormText>
            <div class="form-group ">
              <input type="email" class="form-control " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Search for address or building " />
            </div>
          </FormText>



          <ThirdBox>
            <CallstyleBox>

              <TypoText>
                <NearMeIcon style={{ marginLeft: '-14', marginTop: '-3px', }} />
                <Link data-toggle="modal" data-target="#exampleModalLong" type="submit" style={{ textDecoration: 'none' }}>
                  <CallUsText style={{ textDecoration: 'none', color: 'white' }}>Map Search</CallUsText>

                  <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLongTitle">Your location</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div style={{ height: '400px', width: '100%' }}>
                            <MapGoogle/>
                          </div>
                        </div>
                        <div class="modal-footer">
                        
                          <button type="button" class="btn btn-primary">Add location</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>


              </TypoText>

            </CallstyleBox>
          </ThirdBox>






        </TextBox>

        <MainThBox>
          <Link to='/bookingcollection' style={{ textDecoration: 'none' }} >
            <NextBox>
              <NextText>Next</NextText>
            </NextBox>
          </Link>

          <hr />

          <AdressBox>

            <DoneIcon>
              <CheckCircleIcon style={{ fontSize: '25px', color: 'green' }} />
            </DoneIcon>
            <Link to='/booking' style={{ textDecoration: 'none' }}>
              <AddressText>Address</AddressText>
            </Link>

          </AdressBox>
          <hr />

          <AdressBox>
            <DoneIcon>
              <CheckCircleIcon style={{ fontSize: '30px' }} />
            </DoneIcon>

            <AddressText>Collection time</AddressText>

          </AdressBox>


          <AdressBox>
            <DoneIcon>
              <CheckCircleIcon style={{ fontSize: '30px' }} />
            </DoneIcon>

            <AddressText>Delivery time</AddressText>

          </AdressBox>


          <AdressBox>
            <DoneIcon>
              <CheckCircleIcon style={{ fontSize: '30px' }} />
            </DoneIcon>
            <AddressText>Frequency</AddressText>

          </AdressBox>
          <hr />

          <AdressBox>
            <DoneIcon>
              <CheckCircleIcon style={{ fontSize: '30px' }} />
            </DoneIcon>
            <AddressText>Selected services</AddressText>
          </AdressBox>

          <hr />

          <AdressBox>
            <DoneIcon>
              <CheckCircleIcon style={{ fontSize: '30px' }} />
            </DoneIcon>
            <AddressText>Contact</AddressText>
          </AdressBox>

          <hr />

          <AdressBox>
            <DoneIcon>
              <CheckCircleIcon style={{ fontSize: '30px' }} />
            </DoneIcon>
            <AddressText>Payment</AddressText>
          </AdressBox>

        </MainThBox>

      </MainLast>
    </>
  )
}

export default BookingService
