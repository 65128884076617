import React from 'react'

import FirstHomePage from '../Home/FirstHomePage'

import FourHomePage from '../Home/FourHomePage'
import NineHomePage from '../Home/NineHomePage'
import SecondHomePage from '../Home/SecondHomePage'

import SixHomePage from '../Home/SixHomePage'
import TenHomePage from '../Home/TenHomePage'
import ThirdHomePage from '../Home/ThirdHomePage'

const Home = () => {
  return (
    <>
        <FirstHomePage/>
        <SecondHomePage/>
        <ThirdHomePage/>
        <FourHomePage/>
        <SixHomePage/>
        {/* <SevenHomePage/> */}
        {/* <EightHomePage/> */}
        <NineHomePage/>
        <TenHomePage/>
    </>
  )
}

export default Home
