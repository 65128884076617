import { Box, styled, Typography } from '@mui/material'
import React from 'react'

const MainBoxFild = styled(Box)(({theme})=> ({
    display: 'flex',
    marginTop: '120px',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
        marginTop: '50px',
    }
}))



const SecondMainBox = styled(Box)(({theme})=> ({
    marginLeft: '60px',
    marginBottom: '60px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '10px',
        marginBottom: '60px',
    }
}))



const ServiceText = styled(Typography)(({theme})=> ({
    fontSize: '18px',
    fontWeight: '500',
    color: 'grey',
    marginLeft: '150px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '120px',
    }
}))



const ServiceTextPerFact = styled(Typography)(({theme})=> ({
    fontSize: '42px',
    fontWeight: '600',
    color: '#305349',
    marginLeft: '150px',
    marginTop: '20px',
    lineHeight: '27px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '22px',
        textAlign: 'center',
        marginLeft: '0px',
        marginTop: '20px',
        lineHeight: '15px',

    }
}))



const SecondMainFild  = styled(Box)(({theme})=> ({
    width: '200px',
    height: '50px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 4px 2px #d9d9d9',
    display: 'flex',
    borderRadius: '6px',
    marginLeft: '525px',
    marginTop: '45px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '95px',
        marginTop: '-20px',
    }
}))



const HomeBox = styled(Box)`
    width: 90px;
    height: 40px;
    background: #305349;
    border-radius: 6px;
    margin-top: 5px;
    margin-left: 7px;

`

const HomeBoxSec = styled(Box)`
    width: 90px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 6px;
    margin-top: 5px;
    margin-left: 7px;

`

const HomeFild = styled(Typography)`
    font-size: 18px;
    font-weight: 600;
    color: white;
    text-align: center;
    margin-top: 6px;
`

const HomeFildSec = styled(Typography)`
    font-size: 18px;
    font-weight: 600;
    color: black;
    text-align: center;
    margin-top: 6px;
`;

const SecondServicesBox = styled(Box)(({theme})=> ({
    display: 'flex',
    marginLeft: '208px',
    marginTop: '50px',
    marginBottom: '40px',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
        marginLeft: '55px',

    }
}))



const StyleBox = styled(Box)`
    width: 262px;
    height: 390px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 2px #d9d9d9;
    margin: 9px;
`

const ImageWashing = styled('img')(({theme})=> ({
    width: '60px',
    height: '60px',
    marginLeft: '110px',
    marginTop: '28px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '100px',
    }
}))


const PulmbingText = styled(Typography)`
    font-size: 22px;
    font-weight: 600;
    color: #305349;
    margin-top: 15px;
    text-align: center;
    letter-spacing : 2px;
    
`;

const PulmbingTextSec = styled(Typography)`
    font-size: 22px;
    font-weight: 600;
    color: #305349;
    letter-spacing : 2px;
    text-align: center;
    
`;

const TextYou = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
    color: grey;
    text-align: center;
    margin-top: 10px;
    padding: 10px;
`;

const LearnBox = styled(Box)(({theme})=> ({
    width: '160px',
    height: '40px',
    background: '#305349',
    borderRadius: '5px',
    marginLeft: '67px',
    marginTop: '20px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '53px',
    }
}))



const MoreText = styled(Typography)`
    color: white;
    font-weight: 600;
    text-align: center;
    padding: 5px;
`



const ThirdHomePage = () => {
  return (
    <>
        <MainBoxFild>
            <SecondMainBox>
                <ServiceText>OUR SERVICES</ServiceText>
                <ServiceTextPerFact>Perfect cleanliness</ServiceTextPerFact>
                <ServiceTextPerFact>Without effort!</ServiceTextPerFact>
            </SecondMainBox>

            <SecondMainFild>
                <HomeBox>
                    <HomeFild>Home</HomeFild>
                </HomeBox>
                <HomeBoxSec>
                    <HomeFildSec>Business</HomeFildSec>
                </HomeBoxSec>
            </SecondMainFild>
        </MainBoxFild>

        <SecondServicesBox>
            <StyleBox>
                <ImageWashing src="Image/17.png" alt="" />
                <PulmbingText>Plumbing</PulmbingText>
                <PulmbingTextSec>Service</PulmbingTextSec>
                <TextYou>You fill out on application on the site or contact us in a way convenient for you (phone, email, instant message)</TextYou>

                <LearnBox>
                    <MoreText>Learn More</MoreText>
                </LearnBox>
            </StyleBox>

            <StyleBox>
                <ImageWashing src="Image/16.png" alt="" />
                <PulmbingText>Office space</PulmbingText>
                <PulmbingTextSec>Laundry</PulmbingTextSec>
                <TextYou>You fill out on application on the site or contact us in a way convenient for you (phone, email, instant message)</TextYou>

                <LearnBox>
                    <MoreText>Learn More</MoreText>
                </LearnBox>
            </StyleBox>

            <StyleBox>
                <ImageWashing src="Image/18.png" alt="" />
                <PulmbingText>Maintenance</PulmbingText>
                <PulmbingTextSec>Cleaning</PulmbingTextSec>
                <TextYou>You fill out on application on the site or contact us in a way convenient for you (phone, email, instant message)</TextYou>

                <LearnBox>
                    <MoreText>Learn More</MoreText>
                </LearnBox>
            </StyleBox>

            <StyleBox>
                <ImageWashing src="Image/19.png" alt="" />
                <PulmbingText>Commercial</PulmbingText>
                <PulmbingTextSec>Cleaning</PulmbingTextSec>
                <TextYou>You fill out on application on the site or contact us in a way convenient for you (phone, email, instant message)</TextYou>

                <LearnBox>
                    <MoreText>Learn More</MoreText>
                </LearnBox>
            </StyleBox>
        </SecondServicesBox>
    </>
  )
}

export default ThirdHomePage
