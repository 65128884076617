import { styled, Box, Typography } from '@mui/material'
import React from 'react'


const MainBox = styled(Box)(({theme})=> ({
    display: 'flex',
    marginBottom: '50px',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    }
}))



const ImageHeader = styled('img')(({theme})=> ({
    widht: '230px',
    height: '460px',
    marginTop: '50px',
    marginLeft: '210px',
    [theme.breakpoints.down('lg')]: {
      width: '300px',
      height: '300px',
      marginLeft: '45px',
      marginTop: '10px',
    }
}))



const TextBox = styled(Box)(({theme})=> ({
    marginTop: '140px',
    marginLeft: '170px',

    [theme.breakpoints.down('lg')]: {

      marginLeft: '0px',
      marginTop: '60px',
    }
}))



const AboutText = styled(Typography)(({theme})=> ({
    fontSize: '18px',
    fontWeight: '500',
    color: 'grey',
    [theme.breakpoints.down('lg')]: {
      fontSize: '17px',
      marginLeft: '120px',
    }
}))



const KeepText = styled(Typography)(({theme})=> ({
    fontSize: '42px',
    fontWeight: '600',
    color: '#305349',
    letterSpacing: '1px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '22px',
      textAlign: 'center',
      marginLeft: '0px',
     
    }
}))



const OurText = styled(Typography)(({theme})=> ({
    fontSize: '18px',
    fontWeight: '400',
    color: 'grey',
    marginTop: '20px',
    letterSpacing : '1px',
    width: '72%',
    [theme.breakpoints.down('lg')]: {
      marginTop: '10px',
      textAlign: 'center',
      marginLeft: '60px',
    }
}))



const FourHomePage = () => {
  return (
    <>
      <MainBox>
        <ImageHeader src="Image/1.png" alt="" />
        <TextBox>
            <AboutText>ABOUT COMPANY</AboutText>
            <KeepText>Keeping Your Cloth </KeepText>
            <KeepText>Washing </KeepText>
            <OurText>Our company has been providing professional washing services to residents of Minsk and the Minsk region for more thene 7 years. Washhing of apartments, offices and other premises after repair is a direction in which our employees have gained extensive experience</OurText>
        </TextBox>
      </MainBox>
    </>
  )
}

export default FourHomePage
