import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import InstagramIcon from '@mui/icons-material/Instagram';

import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';

import { MDBFooter, MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';



const Footer = () => {
  return (
    <div>
    <MDBFooter className='text-center' color='white' bgColor='dark'>
        <MDBContainer className='p-4'>
            <section className='mb-4'>
                <a outline floating className='m-3' href='https://youtube.com/channel/UCGoVmE44JqcThXlxgJXf_KA' target="_blank" rel="noopener noreferrer" role='button'>
                    <YouTubeIcon style={{ fontSize: '30px', color: 'white' }} />
                </a>

                <a outline floating className='m-3' href='https://www.facebook.com/' target="_blank" rel="noopener noreferrer" role='button'>
                    <FacebookIcon style={{ fontSize: '30px', color: 'white' }} />
                </a>

                <a outline floating className='m-3' href='https://twitter.com/i/flow/login' target="_blank" rel="noopener noreferrer" role='button'>
                    <TwitterIcon style={{ fontSize: '30px', color: 'white' }} />
                </a>

                <a outline color="light" floating className='m-3' href='https://www.google.com/' target="_blank" rel="noopener noreferrer" role='button'>
                    <GoogleIcon style={{ fontSize: '30px', color: 'white' }} />
                </a>

                <a outline color="light" floating className='m-3' href='https://www.instagram.com/' target="_blank" rel="noopener noreferrer" role='button'>
                    <InstagramIcon style={{ fontSize: '30px', color: 'white' }} />
                </a>

                <a outline color="light" floating className='m-3' href='https://www.linkedin.com/login?fromSignIn=true&trk=guest_homepage-basic_nav-header-signin' target="_blank" rel="noopener noreferrer" role='button'>
                    <aedInIcon style={{ fontSize: '30px', color: 'white' }} />
                </a>

                <a outline color="light" floating className='m-3' href='https://github.com/' target="_blank" rel="noopener noreferrer" role='button'>
                    <GitHubIcon style={{ fontSize: '30px', color: 'white' }} />
                </a>
            </section>

            <section className=''>
                <MDBRow>
                    <MDBCol>
                        <h5 className='text-uppercase'>ABOUT US</h5>

                        <ul className='list-unstyled '>
                            <li style={{ margin: '5px', }}>
                                <a href='/aboutpage' className='text-white' style={{ textDecoration: 'none' }}>
                                    About Us
                                </a>
                            </li>
                            <li style={{ margin: '5px' }}>
                                <a href='#!' className='text-white' style={{ textDecoration: 'none' }}>
                                    Clients
                                </a>
                            </li>
                            <li style={{ margin: '5px' }}>
                                <a href='#!' className='text-white' style={{ textDecoration: 'none' }}>
                                    Testimonials
                                </a>
                            </li>
                            <li style={{ margin: '5px' }}>
                                <a href='#!' className='text-white' style={{ textDecoration: 'none' }}>
                                    Careers
                                </a>
                            </li>
                        </ul>
                    </MDBCol>

                    <MDBCol>
                        <h5 className='text-uppercase'>POLICY</h5>

                        <ul className='list-unstyled '>
                            <li style={{ margin: '5px', }}>
                                <a href='#!' className='text-white' style={{ textDecoration: 'none' }}>
                                    Return Policy
                                </a>
                            </li>
                            <li style={{ margin: '5px' }}>
                                <a href='#!' className='text-white' style={{ textDecoration: 'none' }}>
                                    Terms of Use
                                </a>
                            </li>
                            <li style={{ margin: '5px' }}>
                                <a href='#!' className='text-white' style={{ textDecoration: 'none' }}>
                                    Security
                                </a>
                            </li>
                            <li style={{ margin: '5px' }}>
                                <a href='#!' className='text-white' style={{ textDecoration: 'none' }}>
                                    Privacy
                                </a>
                            </li>
                        </ul>
                    </MDBCol>

                    <MDBCol>
                        <h5 className='text-uppercase'>Contact Us</h5>

                        <ul className='list-unstyled '>
                            <li style={{ margin: '5px', }}>
                                <a href='#!' className='text-white' style={{ textDecoration: 'none' }}>
                                    United States
                                </a>
                            </li>
                            <li style={{ margin: '5px' }}>
                                <a href='#!' className='text-white' style={{ textDecoration: 'none' }}>
                                    United Kingdom
                                </a>
                            </li>
                            <li style={{ margin: '5px' }}>
                                <a href='#!' className='text-white' style={{ textDecoration: 'none' }}>
                                    Australia
                                </a>
                            </li>
                            <li style={{ margin: '5px' }}>
                                <a href='#!' className='text-white' style={{ textDecoration: 'none' }}>
                                    Support
                                </a>
                            </li>
                        </ul>
                    </MDBCol>

                    <MDBCol>
                        <h5 className='text-uppercase'>HELP</h5>

                        <ul className='list-unstyled '>
                            <li style={{ margin: '5px', }}>
                                <a href='#!' className='text-white' style={{ textDecoration: 'none' }}>
                                    Payments
                                </a>
                            </li>
                            <li style={{ margin: '5px' }}>
                                <a href='#!' className='text-white' style={{ textDecoration: 'none' }}>
                                    Shipping
                                </a>
                            </li>
                            <li style={{ margin: '5px' }}>
                                <a href='#!' className='text-white' style={{ textDecoration: 'none' }}>
                                    FAQ
                                </a>
                            </li>
                            <li style={{ margin: '5px' }}>
                                <a href='#!' className='text-white' style={{ textDecoration: 'none' }}>
                                    Report Infringment
                                </a>
                            </li>
                        </ul>
                    </MDBCol>
                </MDBRow>
            </section>
        </MDBContainer>

        <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', }}>
            © 2022 Copyright:
            <a className='text-white' href='/' style={{ textDecoration: 'none' }}>
                laundry
            </a>
        </div>
    </MDBFooter>
</div>
  )
}

export default Footer
